const initialState = {}

export const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            {
                if (state) {
                    delete state['name']
                    delete state['email']
                    delete state['roleId']
                }
                return { ...state, ...action.payload };
            }
        case 'LOGOUT':
            return { ...state, ...action.payload };
        default:
            return state;
    }
}