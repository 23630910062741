import { useState, createContext, useCallback, useEffect, useRef } from "react"

export const LanguageContext = createContext({
  language: "tr",
  translation: {},
  updateLanguage: () => { }
})

export const LanguageProvider = ({ children, fetchTranslations }) => {
  const [{ language, translation }, setLanguage] = useState({
    language: "tr",
    translation: {}
  })
  const initialTranslationsLoaded = useRef(false)

  const updateLanguage = useCallback(
    (newLang) => {
      if (initialTranslationsLoaded.current && newLang === language) return
      const newTranslation = fetchTranslations({ language: newLang })
      initialTranslationsLoaded.current = true
      setLanguage({
        language: newLang,
        translation: newTranslation
      })
    },
    [language, fetchTranslations]
  )

  useEffect(() => {
    updateLanguage(language)
  }, [language, updateLanguage])

  const context = {
    language,
    translation,
    updateLanguage: updateLanguage
  }

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  )
}