import axios from "axios";
import { url, getApiConfig } from "../helpers/constant";
import { setLoading, setMessage } from '../redux/actions/MainAction';
import { store } from '../redux/store/Store';
import { loginChanged } from '../redux/actions/LoginAction';
class ServiceCommon {
  constructor() {
    this.instance = axios.create({ baseURL: url, headers: getApiConfig });
    this.instance.interceptors.response.use(this.successHandler, this.errorHandler);
    this.user = JSON.parse(localStorage.getItem('user'));

  }

  get(path) {
    if (this.user && this.user.token) {
      let config = Object.assign({}, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': this.user.token,
          'Access-Control-Allow-Origin': '*',
        }
      });
      return this.getWithToken(path, config)
    } else {
      return this.getWithoutToken(path);
    }
  }

  getWithoutToken(path) {
    return this.instance.get(path);
  }

  getWithToken(path, config) {
    return this.instance.get(path, config);
  }

  post(path, params) {
    if (this.user && this.user.token) {
      let config = Object.assign({}, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': this.user.token,
          'Access-Control-Allow-Origin': '*',
        }
      });
      return this.postWithToken(path, params, config)
    } else {
      return this.postWithoutToken(path, params);
    }
  }

  postWithoutToken(path, params) {
    return this.instance.post(path, params)
  }

  postWithToken(path, params, config) {
    return this.instance.post(path, params, config)
  }



  getRequestOption(config) {
    if (!config) {
      return {};
    }

    let { method, headers, body } = config;
    return {
      baseURL: url,
      method,
      headers,
      body
    };
  }

  successHandler(response) {
    // console.log('Before Response');
    store.dispatch(setLoading({ loading: false }));
    if (response.data.success) {
      return response.data.result;
    }
    return response.data;
  }

  errorHandler(error) {
    // Do something with response error
    // console.log('Before Response Error');
    store.dispatch(setLoading({ loading: false }));

    //Quasar Toast Schema
    let message = {
      html: 'Network Error.',
      icon: 'fa-warning',
      timeout: 2500,
    }
    //Setup Error Message
    if (typeof error !== 'undefined') {
      if (error.hasOwnProperty('message')) {
        message.html = error.message
      }
    }

    if (typeof error.response !== 'undefined') {

      //Setup Generic Response Messages
      if (error.response.status === 401) {
        message.html = 'UnAuthorized'
        localStorage.removeItem('user');
        localStorage.removeItem('menu');
        localStorage.removeItem('token');
        store.dispatch(setMessage(loginChanged({})));
        window.location = '/';
      } else if (error.response.status === 404) {
        message.html = 'API Route is Missing or Undefined'
      } else if (error.response.status === 405) {
        message.html = 'API Route Method Not Allowed'
      } else if (error.response.status === 422) {
        //Validation Message
      } else if (error.response.status >= 500) {
        store.dispatch(setMessage({ message: 'Sunucu hatası oluştu', severity: 'error' }));
      }

      //Try to Use the Response Message
      if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
        if (error.response.data.hasOwnProperty('message') && error.response.data.message.length > 0) {
          message.html = error.response.data.message
        }
      }
    }

    //Toast the Message
    if (message.html.length > 0) {
      console.log(message);
      //Toast.create.negative(message)
    }
    return Promise.reject(error);
  }
}

export default ServiceCommon