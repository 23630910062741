import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Card } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { setMessage } from '../../redux/actions/MainAction';
import UserService from '../../services/UserService';


function ResetPassword(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const [passwordError, setPasswordError] = useState(null);
  const [userSessionStatus, setSessionStatus] = useState(false);
  const { email, password, id } = inputs;
  let { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    verifyToken();
  }, []);

  function verifyToken() {
    UserService().UserResetPassword({ token })
      .then((data) => {
        let { sessionStatus, email, name, id } = data;
        if (sessionStatus) {
          setSessionStatus(sessionStatus);
        } else {
          setSessionStatus(sessionStatus);
        }
        setInputs(inputs => ({ ...inputs, email, name, id }));
      })
      .catch((error) => {

      });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function validateSubmit() {
    let hasError = false;
    if (!password) {
      dispatch(setMessage({ message: 'Lütfen yeni şifrenizi giriniz', severity: 'warning' }));
      hasError = true;
    }
    
    return hasError;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!validateSubmit()) {
      UserService().UserChangePassword({ id, email, password})
      .then((data) => {
        dispatch(setMessage(data));
        navigate('/login');
      });
    }
  }

  return (
    <BasePage maxWidth="xs">
      <HeroContent>
        <Container maxWidth="sm">
          <Box className={classes.box}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
          </Box>
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>Şifremi Güncelle</Typography>
        </Container>
      </HeroContent>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Card className={classes.card}>
            {
              userSessionStatus ?
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                  <Typography>{inputs.email}</Typography>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Yeni Şifre"
                    type="password"
                    id="password"
                    helperText={passwordError}
                    error={!passwordError ? false : true}
                    onBlur={() => {
                      password == '' ? setPasswordError('Şifrenizi doldurunuz') : setPasswordError(null);
                    }}
                    value={password}
                    autoComplete="current-password"
                    onChange={handleChange}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}>Tamam</Button>
                </form>
                :
                <Typography>{'Şifre sıfırlama linki kullanım dışıdır.'}</Typography>
            }
          </Card>
        </div>
      </Container>
    </BasePage>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  box: {
    display: 'flex',
    justifyContent: 'center'
  },
	card: {
		padding: theme.spacing(2)
	},
}));

export default ResetPassword;