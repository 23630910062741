import React from 'react'
import { getRateColor, getRateColorReverse } from '../helpers/constant';

const styles = {
  progressBarContainer: {
    // width: '90px',
    height: '6px',
    margin: '1px auto',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    // borderRadius: '20px',
    overflow: 'hidden'
  },
  progressBarChild: {
    width: '100%',
    height: '100%'
  },
  progress: {
    color: 'white',
    textAlign: 'center',
    lineHeight: '25px',
    fontSize: '25px',
    fontFamily: "Segoe UI",
    animationDirection: 'reverse',
    // background: '#73bd00',
    /* Chrome10-25,Safari5.1-6 */
    background: 'linear-gradient(to right, #f7d8d6 0%, #c70202 100%)'
    // background: 'linear-gradient(to right, #e5405e 0%, #ffdb3a 25%, #00ca5a 100%)'
  },

  shrinker: {
    backgroundColor: '#e2e2e2',//73bd00,e2e2e2,e5405e
    position: 'absolute',
    top: 0,
    right: 0,
    width: '0%'
  },
  indicator: {
    width: '4%',
    height: '100%',
    zIndex: '1000',
    backgroundColor: 'black',
    position: 'absolute',
    top: 0,
    left: '65%',
    // borderRadius: '50%',
    // borderStyle: 'solid',
    // borderWidth: '2px'
  }
};



function ProgressBar({ indicatorValue }) {

  let rateValue = indicatorValue;
  let color = getRateColor(indicatorValue);

  // const value = { left: (96 - (rateValue * 10)) + '%' };
  const reverseValue = { width: (100 - (rateValue * 10)) + '%' };
  // const indicatorStyle = Object.assign({}, styles.indicator, value);
  const shrinkerStyle = Object.assign({}, styles.progressBarChild, styles.shrinker, reverseValue)

  return (
    <div style={styles.progressBarContainer}>
      {/* <div style={indicatorStyle}></div> */}
      <div style={Object.assign({}, styles.progressBarChild, styles.progress, { backgroundColor: color })}></div>
      <div style={shrinkerStyle}></div>
    </div>
  )
}

export default ProgressBar
