import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App.jsx'
import { BrowserRouter } from 'react-router-dom';

createRoot(document.getElementById('root')).render(
  <BrowserRouter>

    {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
    <App />

    {/* </LocalizationProvider> */}
    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
    {/* </MuiPickersUtilsProvider> */}
  </BrowserRouter>,
)
