export const setLoading = (data) => {
  return {
    type: 'LOADING',
    payload: data
  }
};

export const setMessage = (data) => {
  return {
    type: 'MESSAGE',
    payload: { message: data }
  }
};