import { CustomPaging, FilteringState, PagingState, SortingState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import EditIcon from '@mui/icons-material/Edit';
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { calculateAIRating } from '../../helpers/constant';
import { LanguageContext } from '../../provider/LanguageProvider';
import FilmService from '../../services/FilmService';

const useStyles = makeStyles((theme) => ({
  info: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  chips: {
    margin: 2
  }
}));

export default function UserFilmList(props) {
  const classes = useStyles();
  const { translation } = useContext(LanguageContext);
  const [filmList, setFilmList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageSizes] = useState([20, 50, 100]);
  const [totalCount, setTotalCount] = useState(0);
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'title', direction: 'asc' }]);
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'ratingAI', filteringEnabled: false },
    { columnName: 'id', filteringEnabled: false },
    { columnName: 'published', filteringEnabled: false },
    { columnName: 'action', filteringEnabled: false },
  ]);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'ratingAI', sortingEnabled: false },
  ]);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
    FilmService(true).GetFilms({ pageId: currentPage * pageSize, pageSize: pageSize, filters: filters.length > 0 ? filters[0].value : undefined, sortColumnName: sorting[0].columnName, sortDirection: sorting[0].direction })
      .then((response) => {
        prepareFilmList(response[0]);
        setTotalCount(response[1]);
      })
  }, [user, currentPage, pageSize, filters, sorting]);

  const getRowId = row => row.id;

  const prepareFilmList = (filmList) => {
    filmList.forEach(film => {
      let drug = 0, horror = 0, obscene = 0, swearing = 0, violence = 0;
      film.filmRating.forEach(rate => {
        drug = drug + parseFloat(rate.drug);
        horror = horror + parseFloat(rate.horror);
        obscene = obscene + parseFloat(rate.obscene);
        swearing = swearing + parseFloat(rate.swearing);
        violence = violence + parseFloat(rate.violence);
      });
      let calculatedFilmRating = {};
      const rateCount = film.filmRating.length;
      calculatedFilmRating.drug = (drug / rateCount).toString();
      calculatedFilmRating.horror = (horror / rateCount).toString();
      calculatedFilmRating.obscene = (obscene / rateCount).toString();
      calculatedFilmRating.swearing = (swearing / rateCount).toString();
      calculatedFilmRating.violence = (violence / rateCount).toString();
      let ratingAI = calculateAIRating(calculatedFilmRating);
      film.ratingAI = ratingAI;
    });
    setFilmList(filmList);
  }

  const onEditClicked = (row) => {
    navigate('/addFilm/' + row.imdbId);
  }

  const cellTemplatePublish = (row, columnName) => {
    return row.published == 0
      ? <div>Yayına Değil</div>
      : <div>Yayında</div>
  }

  const cellTemplateAction = (row, columnName) => {
    return (
      <div>
        <IconButton onClick={() => { onEditClicked(row) }}>
          <EditIcon />
        </IconButton>
      </div>
    );
  }

  const CellTemplate = (props) => {
    const { column, row } = props;
    if (column.name === 'published') {
      let style = { color: 'red'};
      if (row.published) {
        style = { color: 'green'}
      } 
      return <Table.Cell {...props} style={style} />;
    }
    return <Table.Cell {...props} />;
  };

  return (
    <BasePage>
      <HeroContent>
        <Container maxWidth="lg">
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>{translation.filmListFilmList}</Typography>
        </Container>
      </HeroContent>

      <Container className={classes.container} maxWidth="lg">
        <Grid item xs={12} sm={12}>
          <Paper variant="outlined">
            <DxGrid
              rows={filmList}
              columns={[
                { name: 'id', title: translation.filmListFilmId },
                { name: 'title', title: translation.filmListFilmName },
                { name: 'ratingAI', title: translation.filmListRateAI },
                { name: 'published', title: translation.filmListPublished, getCellValue: cellTemplatePublish },
                { name: 'action', title: ' ', getCellValue: cellTemplateAction },
              ]}
              getRowId={getRowId}>
              <SortingState
                sorting={sorting}
                onSortingChange={setSorting}
                columnExtensions={sortingStateColumnExtensions}
              />
              <FilteringState onFiltersChange={setFilters} columnExtensions={filteringStateColumnExtensions} />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={(p) => {
                  setCurrentPage(0);
                  setPageSize(p);
                }}
              />
              <CustomPaging totalCount={totalCount} />
              <Table columnExtensions={[
                { columnName: 'id', width: '80' },
                { columnName: 'title', width: 'auto' },
                { columnName: 'ratingAI', width: '100' },
                { columnName: 'published', width: '150', wordWrapEnabled: true },
                { columnName: 'action', width: '120' }
              ]}
                cellComponent={CellTemplate}
              />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <PagingPanel pageSizes={pageSizes} messages={{ rowsPerPage: 'Kayıt Sayısı:' }} />
            </DxGrid>
          </Paper>
        </Grid>
      </Container>
    </BasePage>
  );
}