import { Chip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import FilmService from '../../services/FilmService';

export const FilmTypeSection = ({ onFilmTypeChanged }) => {
    const [activeFilmType, setActiveFilmType] = useState(0);
    const [filmTypeList, setFilmTypeList] = useState(0);

    useEffect(() => {
        FilmService().GetFilmTypesNew()
        .then((response) => {
                setFilmTypeList(response);
            
        });
    }, []);

    let chipList = [];
    chipList.push(
        <Chip
            key={'All'}
            label={'Tümü'}
            clickable
            color={activeFilmType == 0 ? "secondary" : "primary"}
            style={{ margin: '3px' }}
            onClick={() => {
                setActiveFilmType(0);
                onFilmTypeChanged(0);
            }}
        />
    );
    filmTypeList && chipList.push(filmTypeList.map((type) => {
        return <Chip
            key={type.name}
            label={type.name}
            clickable
            color={"secondary"}
            variant={activeFilmType == type.code ? "default" : "outlined"}
            style={{ margin: '3px' }}
            onClick={() => {
                setActiveFilmType(type.code);
                onFilmTypeChanged(type.code);
            }}
        />
    }));

    return (
        <div>
            {chipList}
        </div>
    )
}
