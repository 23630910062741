import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Card } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { asyncLocalStorage } from '../../helpers/constant';
import { validateEmail } from '../../helpers/utils';
import { loginChanged } from '../../redux/actions/LoginAction';
import { setMessage } from '../../redux/actions/MainAction';
import { changeMenuList } from '../../redux/actions/MenuAction';
import UserService from '../../services/UserService';

function SignIn(props) {
	const classes = useStyles();
	const [inputs, setInputs] = useState({ email: '', password: '' });
	const [inputsError, setInputsError] = useState({ emailError: '', passwordError: '' });
	const [loading, setLoading] = useState(false);
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const { email, password } = inputs;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		asyncLocalStorage.getItem('user').then((data) => {
			let userInfo = JSON.parse(data);
			if (userInfo) {
				navigate('/');
			}
		});
	}, []);

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs(inputs => ({ ...inputs, [name]: value }));
	}

	function handleErrorChange(e) {
		let { name, value } = e.target;
		name = name + 'Error';
		value = value && '';
		setInputsError(inputsError => ({ ...inputsError, [name]: value }));
	}

	function validateSubmit() {
		let hasError = false;
		if (!email || !password) {
			dispatch(setMessage({ message: 'Lütfen formu eksiksiz doldurunuz', severity: 'error' }));
			hasError = true;
		}
		if (emailError || passwordError) {
			dispatch(setMessage({ message: 'Lütfen formdaki hatalı alanları düzeltiniz', severity: 'error' }));
			hasError = true;
		}
		if (!hasError) {
			if(!validateEmail(email)) {
				dispatch(setMessage({ message: 'E-posta adresiniz hatalı', severity: 'error' }));
				hasError = true;
			}
		}
		return hasError;
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (!validateSubmit()) {
			setLoading(true);
			UserService().UserSignIn({ email, password })
				.then((data) => {
					setLoading(false);
					if (!data.user) {
						dispatch(setMessage({ message: data, severity: 'error' }))
					}
					dispatch(loginChanged(data.user));
					dispatch(changeMenuList(data.menu));
					asyncLocalStorage.setItem('user', JSON.stringify(data.user))
					asyncLocalStorage.setItem('menu', JSON.stringify(data.menu))
					navigate('/');
				})
		}
	}

	return (
		<BasePage maxWidth={'xs'}>
			<HeroContent>
				<Container maxWidth="sm">
					<Box className={classes.box}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
					</Box>
					<Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>Giriş Yap</Typography>
				</Container>
			</HeroContent>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Card className={classes.card}>
						<form className={classes.form} onSubmit={handleSubmit} noValidate>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								value={email}
								helperText={emailError}
								error={!emailError ? false : true}
								onBlur={handleErrorChange}
								onChange={handleChange}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								helperText={passwordError}
								error={!passwordError ? false : true}
								onBlur={handleErrorChange}
								value={password}
								autoComplete="current-password"
								onChange={handleChange}
							/>
							<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} >Giriş</Button>
							<Grid container>
								<Grid item xs><Link to="/forgotPassword" variant="body2">{"Şifremi unuttum"}</Link></Grid>
								<Grid item><Link to="/signUp" variant="body2">{"Hesabın yok mu? Kaydol"}</Link></Grid>
							</Grid>
						</form>
					</Card>
				</div>
			</Container>
		</BasePage>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	heroContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
	},
	box: {
		display: 'flex',
		justifyContent: 'center'
	},
	card: {
		padding: theme.spacing(4)
	},
}));

export default SignIn;