import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ai from '../../public/assets/ai.png';
import imdb from '../../public/assets/imdb.png';
import { calculateAIRating, getRateColorReverse } from '../helpers/constant';
import noimage from '../image/noimage.png';
import FilmRating from './FilmRating';

const useStyles = makeStyles((theme) => ({
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	cardMedia: {
		paddingTop: '145%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
		paddingBottom: 0,
		padding: 8
	},
	paper: {
		padding: 2,
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

function AlertDialog({ open, onClose }) {
	const [isOpen, setOpen] = React.useState(open);
	const navigate = useNavigate();
	React.useEffect(() => {
		setOpen(open)
	}, [open]);

	const handleClose = () => {
		onClose();
		setOpen(false);
		navigate('/signUp');
	};


	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Uyarı"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Favorilerinize ekleme yapabilmek için öncelikle üye olmanız gerekmektedir.
          </DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Üye Ol
          </Button>
					<Button onClick={onClose} color="primary" autoFocus>
						İptal
          </Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

function getRateEmoji({ ratingAI }) {
	const range = { high: 10, medium: 8, low: 5 };
	let emojiStyle = {
		borderRadius: '50px',
		width: '48px',
		height: '48px',
		backgroundColor: '#fff',
		color: getRateColorReverse(ratingAI, range),
		'WebkitBoxShadow': '0px 0px 10px 3px rgba(0,0,0,0.75)',
		'MozBoxShadow': '0px 0px 10px 3px rgba(0,0,0,0.75)',
		boxShadow: '0px 0px 10px 3px rgba(0,0,0,0.75)',
	};

	if (ratingAI >= range.medium && ratingAI <= range.high) {
		return <SentimentVerySatisfiedIcon style={emojiStyle} />
	}
	else if (ratingAI >= range.low && ratingAI < range.medium) {
		return <SentimentSatisfiedIcon style={emojiStyle} />
	}
	else {
		return <SentimentVeryDissatisfiedIcon style={emojiStyle} />
	}
}

function FilmCard({ film, pageUrl, onLikeClicked, xs, sm, md }) {

	const classes = useStyles();
	const navigate = useNavigate();
	let ratingAI = calculateAIRating(film.filmRating[0]);
	const user = useSelector(state => state.user);

	function handleViewFilm({ film, pageUrl }) {	

	
		navigate('/films/' + film.id + "/" + film.title.toLowerCase().replace(/ /g, '-'));
	}
	
	const RateSection = () => {
		return (
			<Grid container spacing={1}>
				<Grid item xs={6} style={{ display: 'flex', alignItems:'center'}}>
					{/* <Paper className={classes.paper} style={{ backgroundColor: '#c70000' }}> */}
						<img src={ai} height="20"/>
						<Typography component="p" variant="p" style={{ fontSize: 18, fontWeight: 'bolder',  color: '#c70000', marginLeft: 2 }}>
							{`${ratingAI}`}
						</Typography>
					{/* </Paper> */}
				</Grid>
				<Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
					{/* <Paper className={classes.paper} style={{ backgroundColor: '#3fa7b2' }}> */}
					<img src={imdb} height="20"/>
						<Typography component="p" variant="p" style={{ fontSize: 18, fontWeight: 'bold', color: '#000', textAlign: "end", marginLeft: 2 }}>
							{`${film.rating}`}
						</Typography>
					{/* </Paper> */}
				</Grid>
			</Grid>
		)
	}

	return (
		<Grid item key={film.id} xs={xs} sm={sm} md={md}>
			<Card className={classes.card}>
				{/* <CardHeader title={film.title} titleTypographyProps={{ gutterBottom:true, variant:"h6", component:"h2", noWrap: true }} action={getFavoriteIcon(film)}>
				</CardHeader> */}
				<div style={{ position: 'absolute', zIndex: 10, padding: '12px', display: 'flex' }} >

					{getRateEmoji({ ratingAI })}

				</div>
				<CardActionArea onClick={() => { handleViewFilm({ film, pageUrl }) }}>
					{
						<CardMedia
							className={classes.cardMedia}
							image={film.poster ? film.poster : noimage}
							title={film.title}
						/>
					}
				</CardActionArea>
				<CardContent className={classes.cardContent} style={{ paddingBottom: '0px' }}>
					{/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
					<Tooltip title={film.title} placement="top" arrow>
						<Typography gutterBottom variant="h6" component="h2" noWrap>
							{film.title}
						</Typography>
					</Tooltip>
					{/* {user && getFavoriteIcon(film)}
					</div> */}
					<RateSection />
					<Grid item style={{ paddingTop: '10px' }} >
						<FilmRating variant={'caption'} rateValue={film.filmRating && film.filmRating[0].violence} title={'Şiddet'} />
						<FilmRating variant={'caption'} rateValue={film.filmRating && film.filmRating[0].obscene} title={'Müstehcenlik'} />
						<FilmRating variant={'caption'} rateValue={film.filmRating && film.filmRating[0].swearing} title={'Küfür'} />
						<FilmRating variant={'caption'} rateValue={film.filmRating && film.filmRating[0].drug} title={'Madde Kullanımı'} />
						<FilmRating variant={'caption'} rateValue={film.filmRating && film.filmRating[0].horror} title={'Aşırı Sahneler'} />
						{/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<LikeFilmIcon film={film} />
							<FavoriteFilmIcon film={film} />
						</div> */}
						<div style={{ height: 8 }}></div>
					</Grid>
				</CardContent>
				{/* <CardActions>
					<Button
						size="small"
						color="primary"
						startIcon={<ViewIcon />}
						onClick={() => { handleViewFilm({ film }) }}>İncele</Button>
				</CardActions> */}
			</Card>
		</Grid>
	)
}

export default FilmCard;