import { EditingState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, Table, TableEditColumn, TableEditRow, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Card, CardHeader, FormControlLabel, Pagination } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import HeroContent from '../../components/HomeSections/HeroContent';
import MySlider from '../../components/MySlider';
import SearchFilmCard from '../../components/SearchFilmCard';
import BasePage from '../../core/BasePage';
import { convertGenre2, prepareIMDBData } from '../../helpers/utils';
import { LanguageContext } from '../../provider/LanguageProvider';
import { setMessage } from '../../redux/actions/MainAction';
import FilmService from '../../services/FilmService';


function AddFilm() {
  const { translation } = useContext(LanguageContext);
  const classes = useStyles();
  const [id, setFilmId] = useState();
  const [paging, setPaging] = useState({ page: 1, pageSize: 10, totalCount: 0 });
  const [imdbId, setImdbId] = useState('');//tt2096673
  const [searchText, setSearchText] = useState('');
  const [title, setTitle] = useState('');
  const [titleTR, setTitleTR] = useState('');
  const [plot, setPlot] = useState('');
  const [plotTR, setPlotTR] = useState('');
  const [year, setYear] = useState('');
  const [runtime, setTime] = useState('');
  const [genre, setGenre] = useState('');
  const [country, setCountry] = useState('');
  const [director, setDirector] = useState('');
  const [actor, setActor] = useState('');
  const [language, setLanguage] = useState('');
  const [poster, setPoster] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [type, setType] = useState('movie');
  const [rating, setRating] = useState(0);
  const [filmWarning, setFilmWarning] = useState([]);
  const [isEditableAllField, setIsEditableAllField] = useState(0);
  const [filmRating, setFilmRating] = useState({ violence: 0, horror: 0, drug: 0, obscene: 0, swearing: 0 });
  const [searchResults, setSearchResults] = useState([
    // {
    //   "id": "tt0119174",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BZGVmMDNmYmEtNGQ2Mi00Y2ZhLThhZTYtYjE5YmQzMjZiZGMxXkEyXkFqcGdeQXVyNDk3NzU2MTQ@._V1_Ratio0.7273_AL_.jpg",
    //   "title": "The Game",
    //   "description": "(1997)"
    // },
    // {
    //   "id": "tt1034032",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BMTkzMDU0NTg3MF5BMl5BanBnXkFtZTcwNzU1MjU1Mg@@._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Gamer",
    //   "description": "(2009)"
    // },
    // {
    //   "id": "tt0479199",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BZjNmZWQ2N2MtOGM2MS00YWU0LTkzMjctZGMyMWEyNGQyNGUzXkEyXkFqcGdeQXVyMTY5Nzc4MDY@._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Buried Alive",
    //   "description": "(2007) aka \"Game\""
    // },
    // {
    //   "id": "tt0944947",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BYTRiNDQwYzAtMzVlZS00NTI5LWJjYjUtMzkwNTUzMWMxZTllXkEyXkFqcGdeQXVyNDIzMzcwNjc@._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Game of Thrones",
    //   "description": "(2011) (TV Series)"
    // },
    // {
    //   "id": "tt2704998",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BMjI3ODkzNDk5MF5BMl5BanBnXkFtZTgwNTEyNjY2NDM@._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Game Night",
    //   "description": "(I) (2018)"
    // },
    // {
    //   "id": "tt0245712",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BMjQxMWJhMzMtMzllZi00NzMwLTllYjktNTcwZmU4ZmU3NTA0XkEyXkFqcGdeQXVyMTAzMDM4MjM0._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Love's a Bitch",
    //   "description": "(2000) aka \"Game Phan Du Khon Phan Hoad\""
    // },
    // {
    //   "id": "tt1545660",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BMTQ3ODEwMzY3NV5BMl5BanBnXkFtZTgwNTkwMTQ5MDE@._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Knights of Badassdom",
    //   "description": "(2013) aka \"GAME WARRIORS エバーモアの戦い\""
    // },
    // {
    //   "id": "tt3322364",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BMTYwNjgwNDg0NV5BMl5BanBnXkFtZTgwMzY1MjAyNzE@._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Concussion",
    //   "description": "(2015) aka \"Game Brain\""
    // },
    // {
    //   "id": "tt10919420",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BYWE3MDVkN2EtNjQ5MS00ZDQ4LTliNzYtMjc2YWMzMDEwMTA3XkEyXkFqcGdeQXVyMTEzMTI1Mjk3._V1_Ratio0.7273_AL_.jpg",
    //   "title": "Squid Game",
    //   "description": "(2021) (TV Mini Series)"
    // },
    // {
    //   "id": "tt1844624",
    //   "resultType": "Title",
    //   "image": "https://imdb-api.com/images/original/MV5BMWFhOGI5ZmQtNjA1My00NWExLTg1MDktYThkYWI3ZTc4ZDYzXkEyXkFqcGdeQXVyMTEyMjM2NDc2._V1_Ratio0.7273_AL_.jpg",
    //   "title": "American Horror Story",
    //   "description": "(2011) (TV Series)"
    // }
  ]);

  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { imdbCode } = useParams();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    else {
      if (imdbCode) {
        //TODO: Burası direk film çekecek şekilde değiştirilecek.
        FilmService().IsFilmExist({ imdbId: imdbCode, userId: user.id })
          .then((data) => {
            if (data) {
              setIsUpdate(true);
              convertGenre2(data.filmGenre);
              setFilmData(data, true);
              dispatch(setMessage({ message: 'Film bilgileri sistemden getirildi', severity: 'success' }));
            }
          });
      }
    }
  }, [user]);

  const getRowId = row => row.id;

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = filmWarning.length > 0 ? filmWarning[filmWarning.length - 1].id + 1 : 0;
      changedRows = [
        ...filmWarning,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          userId: user.id,
          ...row,
        })),
      ];
    }
    if (changed) {
      changedRows = filmWarning.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }
    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = Object.assign([], filmWarning);
      changedRows.forEach(row => {
        if (deletedSet.has(row.id)) {
          row.isDeleted = true;
        }
      });
    }
    setFilmWarning(changedRows);
  }

  function onFilmRatingChange({ key, value }) {
    setFilmRating(filmRating => ({ ...filmRating, [key]: value }));
  }

  const filterFilmRatingByUser = (filmRating) => {
    let ratingDefault = { violence: 0, horror: 0, drug: 0, obscene: 0, swearing: 0 };
    let filmRatingByUser = filmRating.find(p => p.userId.id === user.id)
    return filmRatingByUser ? filmRatingByUser : ratingDefault;
  }

  function setFilmData(film, isExist) {
    let { id, title, titleTR, plot, plotTR, year, runtime, filmGenre, country, language, rating, filmRating, imdbId, poster, filmWarning, director, actor } = film;
    setFilmId(id)
    setTitle(title);
    setTitleTR(titleTR);
    setPlot(plot);
    setPlotTR(plotTR);
    setYear(year);
    setTime(runtime);
    setGenre(filmGenre);
    setCountry(country);
    setLanguage(language);
    setRating(rating);
    setImdbId(imdbId);
    setPoster(poster);
    setFilmRating(filterFilmRatingByUser(filmRating));
    setDirector(director);
    setActor(actor);
    setFilmWarning(filmWarning);
  }

  function onSearchTextClicked() {
    if (searchText.length < 3) {
      dispatch(setMessage({ message: 'En az 3 karakter giriniz', severity: 'warning' }));
      return;
    }

    FilmService().GetFilmsBySearchText({ searchText, page: 1 })
      .then((data) => {
        console.log("data", data);
        if (data && data.Response == "False") {
          dispatch(setMessage({ message: 'Film bulunamadı', severity: 'warning' }));
        }
        else {
          setSearchResults(data.Search);
          setPaging({ page: 0, pageSize: 10, totalCount: data.totalResults });
          dispatch(setMessage({ message: data.totalResults + ' adet film bulundu', severity: 'success' }));
        }



        // if (data && data.results) {
        //   setSearchResults(data.results);
        // }
        // else if (data.errorMessage) {
        //   dispatch(setMessage({ message: 'Film arama limiti doldu. 24 saat sonra tekrar deneyiniz.', severity: 'warning' }));
        // }
      })
  }

  function onSearchClicked(imdbFilmId) {
    FilmService().IsFilmExist({ imdbId: imdbFilmId, userId: user.id })
      .then((data) => {
        if (data) {
          // setIsEditableAllField(data.published);
          setIsUpdate(true);
          convertGenre2(data.filmGenre);
          setFilmData(data, true);
          dispatch(setMessage({ message: 'Film bilgileri sistemden getirildi', severity: 'success' }));
        }
        else {
          FilmService().GetIMDBfilm({ imdbId: imdbFilmId })
            .then((data) => {
              console.log("data", data);

              const { Title, Plot, Year, Runtime, Genre, Country, Language, imdbRating, imdbID, Poster, Type, Director, Actors } = data;
              setImdbId(imdbID);
              setFilmId(null);
              setTitle(Title);
              setPlot(Plot);
              setYear(Year);
              setTime(Runtime);
              setCountry(new Array(Country));
              setLanguage(new Array(Language));
              setRating(imdbRating);
              setPoster(Poster);
              setType(Type);
              setDirector(Director);
              setActor(Actors);
              setGenre(new Array(Genre));
              setFilmRating({ violence: 0, horror: 0, drug: 0, obscene: 0, swearing: 0 })
              setFilmWarning([]);
              dispatch(setMessage({ message: 'Film bilgileri getirildi', severity: 'success' }));
              // setIsEditableAllField(0);
              // let { title, plot, year, directors, actors, runtime, genre, countries, languages, imDbRating, imdbId, poster, type } = prepareIMDBData(data);
              // setFilmId(null);
              // setTitle(title);
              // setPlot(plot);
              // setYear(year);
              // setTime(runtime);
              // setCountry(countries);
              // setLanguage(languages);
              // setRating(imDbRating);
              // setImdbId(imdbId);
              // setPoster(poster);
              // setType(type);
              // setDirector(directors);
              // setActor(actors);
              // setGenre(genre);
              // setFilmRating({ violence: 0, horror: 0, drug: 0, obscene: 0, swearing: 0 })
              // setFilmWarning([]);
              // dispatch(setMessage({ message: 'Film bilgileri getirildi', severity: 'success' }));
            })
        }
      });

  }

  function onSaveClicked() {

    filmRating.userId = { id: user.id };
    let data = {
      id, imdbId, title, titleTR, year, runtime, plot, plotTR, language, poster, country, director, actor, rating, published: 0,
      filmGenre: genre,
      filmRating: [filmRating],
      filmWarning: filmWarning
    };

    isUpdate ?
      FilmService().UpdateFilmService(data)
        .then((response) => {
          dispatch(setMessage({ message: 'Film Kaydedildi', severity: 'success' }));
        })
      :
      FilmService().AddFilmService(data)
        .then((response) => {

          let filmId = response.id;
          setFilmId(filmId);
          convertGenre2(response.filmGenre);
          setGenre(response.filmGenre);
          setFilmRating(response.filmRating[0]);
          setFilmWarning(response.filmWarning);
          dispatch(setMessage({ message: 'Film Kaydedildi', severity: 'success' }))
        });
  }

  const rowComponent = (data) => {
    return <Table.Row {...data} className={data.row.isDeleted && classes.deleted} />
  }

  return (
    <BasePage>
      <HeroContent>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>{translation.addFilmAddFilm}</Typography>
          <Typography className={classes.info}>
            Eklemek istediğiniz filmin adını arama kutusuna yazın ve enter tuşuna basın. Ardından sonuç listesine gelen filmlerden eklemek istediğinizi seçip puanlamanızı yapabilirsiniz.
            {/* {translation.addFilmAddDescription1}
            <Link href="https://www.imdb.com/" target="_blank" rel="noopener" style={{ fontWeight: 'bold' }}>IMDB</Link>
            {translation.addFilmAddDescription2}
            <span style={{ fontWeight: 'bold', marginLeft: '8px' }}>"tt"</span>
            {translation.addFilmAddDescription3}{<SearchIcon style={{ margin: 0 }} />}
            {translation.addFilmAddDescription4} */}
          </Typography>
        </Container>
      </HeroContent>
      <Container className={classes.container} maxWidth="md">
        <Card className={classes.cardSearch} >
          <CardHeader title={"Film Arama"}></CardHeader>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>


            <TextField
              required
              label={"Film Ara"}
              fullWidth
              value={searchText}
              disabled={isEditableAllField}
              variant="outlined"
              style={{ backgroundColor: 'rgb(248, 234, 253)' }}
              onChange={(e) => { setSearchText(e.target.value) }}
              onKeyDown={(e) => {
                if (e.key == "Enter" && e.target.value.length > 0) {
                  onSearchTextClicked()
                }
              }}
            />
            <IconButton
              variant="outlined"
              color="secondary"
              disabled={isEditableAllField}
              style={{ float: 'right', marginLeft: '12px' }}
              onClick={onSearchTextClicked}>
              <SearchIcon />
            </IconButton>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              startIcon={<SaveIcon />}
              disabled={!imdbId || !title || !plot}
              onClick={onSaveClicked}>
              Kaydet
            </Button>
          </Box>

          {
            searchResults.length > 0 ?

              <Grid container className={classes.cardSerachResults} spacing={0}>
                <Grid item style={{ paddingLeft: 16, paddingRight: 8 }}>
                  <Typography gutterBottom variant="p" >
                    Arama Sonuçları:
                  </Typography>
                </Grid>
                <div style={{ width: "100%", overflow: "auto", display: "flex", paddingLeft: 8, paddingRight: 8 }}>
                  {
                    searchResults.map((film, index) => {
                      return <SearchFilmCard key={index} film={film} handleOnClick={(data) => {
                        onSearchClicked(data.film.imdbID);

                        // const { Title, Plot, Year, Runtime, Genre, Country, Language, imDbRating, imdbID, Poster, Type, Director, Actors } = data.film;
                        // setImdbId(imdbID);
                        // setFilmId(null);
                        // setTitle(Title);
                        // setPlot(Plot);
                        // setYear(Year);
                        // setTime(Runtime);
                        // setCountry(new Array(Country));
                        // setLanguage(new Array(Language));
                        // setRating(imDbRating);
                        // setPoster(Poster);
                        // setType(Type);
                        // setDirector(Director);
                        // setActor(Actors);
                        // setGenre(new Array(Genre));
                        // setFilmRating({ violence: 0, horror: 0, drug: 0, obscene: 0, swearing: 0 })
                        // setFilmWarning([]);
                        // dispatch(setMessage({ message: 'Film bilgileri getirildi', severity: 'success' }));
                      }} />;
                    })
                  }
                </div>
                {
                  paging.totalCount > 10 ?
                    <Pagination count={Math.ceil(paging.totalCount / paging.pageSize)} color="primary" style={{ margin: 'auto' }} onChange={(event, page) => {
                      setPaging({ ...paging, page: page });
                      FilmService().GetFilmsBySearchText({ searchText, page: page })
                        .then((data) => {
                          setSearchResults(data.Search);
                          setPaging({ page: page, pageSize: 10, totalCount: data.totalResults });
                          dispatch(setMessage({ message: data.totalResults + ' adet film bulundu', severity: 'success' }));
                        })
                    }} />
                    :
                    null
                }
              </Grid>
              :
              null
          }
        </Card>
        <Card className={classes.card}>
          <CardHeader title={"Film Bilgileri"}></CardHeader>
          <Grid container spacing={3} >
            {/* <Grid item xs={12} sm={12} md={12} style={{ display: 'flex' }} >
              <TextField
                required
                label={translation.addFilmFilmCode}
                fullWidth
                value={imdbId}
                disabled={isEditableAllField}
                variant="outlined"
                style={{ backgroundColor: 'rgb(248, 234, 253)' }}
                onChange={(e) => { setImdbId(e.target.value) }}
              />
              <IconButton
                variant="outlined"
                color="secondary"
                disabled={isEditableAllField}
                style={{ float: 'right', marginLeft: '12px' }}
                onClick={onSearchClicked}>
                <SearchIcon />
              </IconButton>

            </Grid> */}
            <Grid item xs={12} sm={12}>
              {/* <Tabs>
                <Tab label='Film Adı - EN' value={<div>asdasd</div>}>asdas</Tab>
                <Tab label='Film Adı - TR' value={<div>asdasd</div>}></Tab>
              </Tabs> */}
              <TextField
                required
                id="title"
                name="title"
                label={translation.addFilmFilmName}
                // disabled={isEditableAllField}
                fullWidth
                value={title}
                variant="outlined"
                onChange={(e) => { setTitle(e.target.value) }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="titleTR"
                name="titleTR"
                label={translation.addFilmFilmNameTR}
                // disabled={isEditableAllField}
                fullWidth
                value={titleTR}
                variant="outlined"
                onChange={(e) => { setTitleTR(e.target.value) }}
              />
            </Grid>
            {/* {isEditableAllField === 0 &&  */}
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id="plot"
                name="plot"
                label={translation.addFilmDescription}
                fullWidth
                multiline
                value={plot}
                variant="outlined"
                onChange={(e) => { setPlot(e.target.value) }}
              />
              <TextField
                id="plotTR"
                name="plotTR"
                label={translation.addFilmDescriptionTR}
                fullWidth
                multiline
                value={plotTR}
                variant="outlined"
                style={{ marginTop: '24px' }}
                onChange={(e) => { setPlotTR(e.target.value) }}
              />
            </Grid>
            {/* } */}
            {isEditableAllField === 0 && <Grid item xs={12} sm={12} md={12} style={{ display: 'flex' }} >
              <TextField
                label={translation.addFilmPosterLink}
                fullWidth
                value={poster}
                variant="outlined"
                style={{}}
                onChange={(e) => { setPoster(e.target.value) }}
              />
            </Grid>}
            <Grid item xs={12} sm={6}>
              <img src={poster} alt="" style={{ width: '300px', borderRadius: '5px' }}></img>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MySlider title={translation.addFilmViolence} onChange={(value) => { onFilmRatingChange({ key: 'violence', value }); }} value={filmRating.violence} />
              <MySlider title={translation.addFilmObscene} onChange={(value) => { onFilmRatingChange({ key: 'obscene', value }); }} value={filmRating.obscene} />
              <MySlider title={translation.addFilmSwearing} onChange={(value) => { onFilmRatingChange({ key: 'swearing', value }); }} value={filmRating.swearing} />
              <MySlider title={translation.addFilmDrug} onChange={(value) => { onFilmRatingChange({ key: 'drug', value }); }} value={filmRating.drug} />
              <MySlider title={translation.addFilmHorror} onChange={(value) => { onFilmRatingChange({ key: 'horror', value }); }} value={filmRating.horror} />
            </Grid>
            {isEditableAllField === 0 && <Grid container spacing={2} className={classes.gridContainer}><Grid item xs={12} sm={6}>
              <TextField
                id="year"
                name="year"
                label={translation.addFilmYear}
                fullWidth
                value={year}
                variant="outlined"
                onChange={(e) => { setYear(e.target.value) }}
              />
            </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="runtime"
                  name="runtime"
                  label={translation.addFilmTime}
                  fullWidth
                  value={runtime}
                  variant="outlined"
                  onChange={(e) => { setTime(e.target.value) }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField id="country" name="country" label={translation.addFilmCountry} fullWidth
                  variant="outlined"
                  value={country}
                  onChange={(e) => { setCountry(e.target.value) }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField id="director" name="director" label={translation.addFilmDirector} fullWidth
                  variant="outlined"
                  value={director}
                  onChange={(e) => { setDirector(e.target.value) }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField id="actor" name="actor" label={translation.addFilmActor} fullWidth
                  variant="outlined"
                  value={actor}
                  onChange={(e) => { setActor(e.target.value) }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField id="language" name="language" label={translation.addFilmLanguage} fullWidth
                  variant="outlined"
                  value={language}
                  onChange={(e) => { setLanguage(e.target.value) }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField id="rating" name="rating" label={translation.addFilmIMDB} fullWidth
                  variant="outlined"
                  value={rating}
                  onChange={(e) => { setRating(e.target.value) }} />
              </Grid>
              <Grid item xs={12} sm={12}>
                {
                  genre && genre.map((item, index) => <FormControlLabel key={index} label={item.name} control={<Checkbox id={item.id} value={item.id} checked />} />)
                }
              </Grid></Grid>}
            <Grid item xs={12} sm={12}>
              <Paper variant="outlined">
                <DxGrid
                  rows={filmWarning}
                  columns={[
                    { name: 'label', title: translation.addFilmLabel },
                    { name: 'startTime', title: translation.addFilmStartTime },
                    { name: 'endTime', title: translation.addFilmEndTime },
                  ]}
                  getRowId={getRowId}>
                  <Toolbar rootComponent={() => { return <Typography variant="h6" className={classes.gridHeader}>{translation.addFilmFilmWarning}</Typography> }} />
                  <EditingState onCommitChanges={commitChanges} />
                  <Table rowComponent={rowComponent} />
                  <TableHeaderRow />
                  <TableEditRow />
                  <TableEditColumn showAddCommand showEditCommand showDeleteCommand messages={{ addCommand: 'Ekle', editCommand: 'Düzenle', deleteCommand: 'Sil', commitCommand: 'Kaydet', cancelCommand: 'İptal' }} />
                </DxGrid>
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </BasePage>
  );
}

const useStyles = makeStyles((theme) => ({
  info: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2)
  },
  cardSerachResults: {
  },
  searchTitle: {
    padding: theme.spacing(2)
  },
  cardSearch: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  gridContainer: {
    padding: theme.spacing(2)
  },
  gridHeader: {
    margin: '8px 32px',
  },
  deleted: {
    backgroundColor: theme.palette.error.light
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default AddFilm;