import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

let Logo = (props) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg"  height="80" viewBox="0 0 435.33 102.67" >
<style type="text/css">
	{`.st0{fill:#fff;}
	.st1{fill:#EB5754;}
	.st2{fill:#FED507;}
	.st3{fill:#29ABE3;}
	.st4{fill:#664E9B;}
	.st5{opacity:0.47;fill:#fff;}
	.st6{fill:#fff;}
	.st7{fill:#020203;}`}
</style>
<path className="st0" d="M32.27,53.77c-0.01,0.25-0.02,0.51-0.02,0.76c0,9.25,7.5,16.74,16.74,16.74c4.03,0,7.73-1.43,10.62-3.8
	c3.74-3.07,6.12-7.73,6.12-12.94c0-8.14-5.81-14.92-13.5-16.43c-1.05-0.21-2.13-0.32-3.24-0.32C40,37.79,32.67,44.88,32.27,53.77z"
	/>
<path className="st1" d="M49,63.3c-9.25,0-11.15,6.07-11.15-3.17c0-0.26,2.21-6.06,2.22-6.31c-1.25-0.11-10.3-0.21-11.58-0.21
	c-0.5,0-0.99,0.01-1.49,0.03c0.01,0.29,0.02,0.58,0.02,0.88c0,9.32-7.02,16.99-16.06,18.03C17.71,86.76,32.2,96.6,49,96.6
	c7.7,0,14.91-2.07,21.12-5.68c0-8.73-7.43-21.19-13.16-27.54C54.07,65.76,53.03,63.3,49,63.3z"/>
<path className="st0" d="M85.51,39.24c-1.19,0-2.35,0.15-3.46,0.43c1.33,3.19,2.21,6.6,2.55,10.18l0,0l0,0.01
	c0.11,1.14,0.17,2.29,0.17,3.45c0,1.06-0.05,2.1-0.14,3.13l0.05,10.92c0.27,0.02,0.55,0.03,0.83,0.03c7.77,0,14.08-6.3,14.08-14.08
	S93.29,39.24,85.51,39.24z"/>
<path className="st2" d="M91.09,54.5c0-1.38-0.07-2.74-0.2-4.09l0-0.02l0,0c-0.41-4.23-1.45-8.28-3.02-12.05
	c-7.22,1.82-12.57,8.36-12.57,16.16c0,8.87,6.94,16.13,15.69,16.63l-0.06-12.93C91.04,56.99,91.09,55.75,91.09,54.5z"/>
<path className="st3" d="M75.3,54.51c0-7.79,5.35-14.33,12.57-16.16c-3.63-8.72-10.09-15.96-18.25-20.55
	c-7.75,4.73-17.48,15.29-18.4,23.97c7.69,1.51-10.69,12.73,14.52,12.76c5.21,0.01-4.24,6.69-7.98,9.76
	c5.72,6.36,11.38,17.66,12.35,26.63c0,0,0.01,0,0.01,0l0,0c9.46,6.79,20.97,5.68,20.97,5.68l-0.11-25.46
	C82.24,70.63,75.3,63.38,75.3,54.51z"/>
<path className="st4" d="M49,12.41c-5.52,0-10.79,1.06-15.62,2.99c-9.87,3.95-17.9,11.52-22.43,21.08c5.76,0.66,10.7,4.01,13.53,8.77
	c1.47,2.47,2.37,5.33,2.52,8.38c0.49-0.02,0.99-0.03,1.49-0.03c1.28,0,8.97,0.06,10.22,0.17c0.4-8.89,1.85-5.62,10.84-5.62
	c1.11,0,0.7-6.83,1.75-6.62C54,32.38,61.87,22.52,69.62,17.8C63.53,14.36,56.49,12.41,49,12.41z"/>
<path className="st5" d="M22.22,54.07c-0.23,0.03-0.46,0.07-0.69,0.11C21.75,54.14,21.99,54.1,22.22,54.07z"/>
<path className="st5" d="M-102.62,6.04c-0.48,0.21-0.96,0.42-1.44,0.64C-103.58,6.47-103.1,6.25-102.62,6.04z"/>
<path className="st5" d="M19.53,54.56c-0.23,0.05-0.45,0.1-0.68,0.15C19.08,54.66,19.31,54.61,19.53,54.56z"/>
<path className="st5" d="M13.12,56.51c-0.23,0.09-0.45,0.18-0.68,0.28C12.67,56.69,12.9,56.6,13.12,56.51z"/>
<path className="st5" d="M23.59,53.89c-0.23,0.03-0.47,0.05-0.7,0.08C23.12,53.94,23.35,53.91,23.59,53.89z"/>
<path className="st5" d="M18.22,54.87c-0.23,0.06-0.45,0.11-0.68,0.18C17.77,54.99,17.99,54.93,18.22,54.87z"/>
<path className="st5" d="M16.92,55.22c-0.22,0.06-0.45,0.13-0.67,0.2C16.47,55.35,16.69,55.29,16.92,55.22z"/>
<path className="st5" d="M15.63,55.61c-0.22,0.07-0.45,0.15-0.67,0.22C15.19,55.76,15.41,55.68,15.63,55.61z"/>
<path className="st5" d="M14.37,56.04c-0.22,0.08-0.45,0.16-0.67,0.25C13.92,56.2,14.14,56.12,14.37,56.04z"/>
<path className="st5" d="M-105.15,7.18c-0.5,0.23-0.99,0.48-1.48,0.72C-106.14,7.66-105.64,7.41-105.15,7.18z"/>
<path className="st5" d="M-110.07,9.69c-0.81,0.44-1.6,0.9-2.39,1.36C-111.67,10.58-110.88,10.13-110.07,9.69z"/>
<path className="st5" d="M24.99,53.75c-0.25,0.02-0.49,0.04-0.74,0.06C24.49,53.79,24.74,53.77,24.99,53.75z"/>
<path className="st6" d="M23.48,45.28c0.33,0,0.66-0.01,0.99-0.03c-2.83-4.76-7.77-8.11-13.53-8.77c-0.31,0.65-0.6,1.31-0.88,1.97
	C13.09,42.59,17.97,45.28,23.48,45.28z"/>
<path className="st2" d="M26.99,53.63c-0.15-3.05-1.05-5.9-2.52-8.38c-2.83-4.76-7.77-8.11-13.53-8.77c-0.31,0.65-0.6,1.31-0.88,1.97
	C8.03,43.4,6.9,48.82,6.9,54.5c0,1.66,0.1,3.3,0.29,4.91c0.54,4.65,1.84,9.06,3.77,13.12c9.04-1.04,16.06-8.71,16.06-18.03
	C27.01,54.21,27,53.92,26.99,53.63z"/>
<circle className="st1" cx="76.53" cy="21.08" r="17.41"/>
<g>
	<path className="st1" d="M122.98,59.37c0,0.83-0.27,1.53-0.8,2.1c-0.54,0.57-1.24,0.86-2.11,0.86c-0.72,0-1.31-0.24-1.75-0.72
		c-0.45-0.48-0.77-0.99-0.97-1.52c-0.65,0.8-1.36,1.37-2.15,1.72s-1.74,0.52-2.86,0.52c-1.27,0-2.45-0.26-3.55-0.77
		c-1.1-0.52-2.05-1.22-2.86-2.11c-0.81-0.89-1.44-1.94-1.9-3.15c-0.46-1.21-0.69-2.51-0.69-3.89s0.23-2.68,0.69-3.89
		c0.46-1.21,1.09-2.26,1.9-3.15c0.81-0.89,1.76-1.6,2.86-2.11c1.09-0.52,2.28-0.77,3.55-0.77c1.12,0,2.07,0.15,2.86,0.44
		c0.78,0.29,1.5,0.77,2.15,1.43c0.2-0.45,0.52-0.87,0.97-1.27c0.45-0.4,1.03-0.6,1.75-0.6c0.87,0,1.57,0.29,2.11,0.86
		c0.53,0.57,0.8,1.27,0.8,2.1V59.37z M111.45,48.05c-0.5,0.24-0.92,0.57-1.25,0.98s-0.59,0.89-0.77,1.44
		c-0.17,0.55-0.26,1.13-0.26,1.75c0,0.62,0.09,1.2,0.26,1.74c0.17,0.55,0.43,1.03,0.77,1.44c0.34,0.41,0.75,0.74,1.25,0.98
		c0.5,0.24,1.07,0.36,1.72,0.36c0.65,0,1.22-0.12,1.72-0.36c0.5-0.24,0.91-0.56,1.25-0.98c0.34-0.41,0.59-0.89,0.77-1.44
		c0.17-0.55,0.26-1.13,0.26-1.74c0-0.62-0.09-1.2-0.26-1.75c-0.17-0.55-0.43-1.03-0.77-1.44c-0.34-0.41-0.75-0.74-1.25-0.98
		c-0.5-0.24-1.07-0.36-1.72-0.36C112.52,47.69,111.95,47.81,111.45,48.05z"/>
	<path className="st1" d="M125.17,35.69c0.19-0.41,0.43-0.78,0.75-1.1c0.31-0.32,0.68-0.57,1.1-0.75c0.42-0.17,0.87-0.26,1.34-0.26
		c0.47,0,0.92,0.09,1.34,0.26c0.42,0.17,0.79,0.42,1.1,0.75c0.31,0.32,0.56,0.69,0.75,1.1c0.19,0.41,0.28,0.87,0.28,1.36
		c0,0.47-0.09,0.92-0.28,1.34c-0.19,0.42-0.44,0.8-0.75,1.12c-0.31,0.32-0.68,0.57-1.1,0.75c-0.42,0.17-0.87,0.26-1.34,0.26
		c-0.47,0-0.92-0.09-1.34-0.26c-0.42-0.17-0.79-0.42-1.1-0.75s-0.56-0.7-0.75-1.12c-0.19-0.42-0.28-0.87-0.28-1.34
		C124.89,36.56,124.98,36.1,125.17,35.69z M125.45,45.42c0-0.83,0.27-1.53,0.8-2.1c0.54-0.57,1.24-0.86,2.11-0.86
		c0.87,0,1.57,0.29,2.11,0.86c0.53,0.57,0.8,1.27,0.8,2.1v13.94c0,0.83-0.27,1.53-0.8,2.1c-0.54,0.57-1.24,0.86-2.11,0.86
		c-0.87,0-1.57-0.29-2.11-0.86c-0.54-0.57-0.8-1.27-0.8-2.1V45.42z"/>
	<path className="st1" d="M133.73,36.58c0-0.84,0.27-1.55,0.8-2.13s1.24-0.87,2.11-0.87s1.57,0.29,2.11,0.87
		c0.53,0.58,0.8,1.29,0.8,2.13v22.74c0,0.84-0.27,1.55-0.8,2.13c-0.54,0.58-1.24,0.87-2.11,0.87s-1.57-0.29-2.11-0.87
		s-0.8-1.29-0.8-2.13V36.58z"/>
	<path className="st1" d="M147.62,54.3c0,0.24,0.07,0.52,0.21,0.84c0.14,0.32,0.38,0.6,0.73,0.87c0.35,0.26,0.83,0.49,1.44,0.67
		c0.61,0.18,1.38,0.27,2.3,0.27c0.72,0,1.38-0.09,1.96-0.28c0.58-0.19,1.12-0.4,1.6-0.63c0.49-0.24,0.93-0.45,1.33-0.63
		c0.4-0.19,0.76-0.28,1.08-0.28c0.65,0,1.21,0.23,1.7,0.69c0.49,0.46,0.73,1.06,0.73,1.81c0,0.7-0.27,1.34-0.8,1.92
		c-0.54,0.58-1.24,1.08-2.11,1.49c-0.87,0.41-1.85,0.73-2.95,0.95s-2.19,0.34-3.29,0.34c-1.57,0-2.98-0.26-4.24-0.77
		s-2.33-1.22-3.21-2.11c-0.88-0.89-1.56-1.94-2.03-3.15c-0.47-1.21-0.71-2.51-0.71-3.89c0-1.32,0.25-2.57,0.75-3.77
		c0.5-1.2,1.2-2.25,2.11-3.17c0.91-0.92,1.98-1.64,3.23-2.18c1.24-0.54,2.61-0.81,4.11-0.81c1.44,0,2.74,0.27,3.9,0.8
		s2.15,1.23,2.97,2.1c0.82,0.86,1.46,1.87,1.9,3c0.45,1.14,0.67,2.31,0.67,3.52c0,0.97-0.24,1.62-0.71,1.94
		c-0.47,0.32-1.18,0.48-2.13,0.48H147.62z M155.16,50.31c0-0.27-0.09-0.57-0.26-0.91c-0.17-0.34-0.43-0.66-0.77-0.95
		c-0.34-0.29-0.73-0.54-1.19-0.74c-0.46-0.21-0.98-0.31-1.55-0.31c-0.57,0-1.09,0.1-1.55,0.31c-0.46,0.21-0.86,0.45-1.19,0.74
		c-0.34,0.29-0.59,0.6-0.76,0.95c-0.17,0.34-0.26,0.64-0.26,0.91H155.16z"/>
	<path className="st1" d="M162.81,48.51c0.47-1.21,1.14-2.26,2-3.15c0.86-0.89,1.89-1.6,3.1-2.11c1.21-0.52,2.54-0.77,4.01-0.77
		c0.65,0,1.38,0.06,2.18,0.17c0.81,0.11,1.56,0.31,2.26,0.6c0.7,0.29,1.28,0.68,1.75,1.18s0.71,1.13,0.71,1.9
		c0,0.8-0.23,1.45-0.69,1.96c-0.46,0.51-1.03,0.77-1.7,0.77c-0.37,0-0.7-0.05-0.97-0.15c-0.27-0.1-0.55-0.21-0.84-0.34
		c-0.29-0.13-0.61-0.24-0.97-0.34c-0.36-0.1-0.83-0.15-1.4-0.15c-0.75,0-1.39,0.11-1.92,0.34c-0.54,0.23-0.98,0.54-1.34,0.93
		c-0.36,0.39-0.63,0.85-0.8,1.38c-0.17,0.52-0.26,1.08-0.26,1.67s0.09,1.15,0.26,1.67c0.17,0.53,0.43,0.98,0.77,1.38
		s0.75,0.71,1.25,0.93c0.5,0.23,1.07,0.34,1.72,0.34c0.62,0,1.17-0.05,1.64-0.15s0.88-0.22,1.23-0.35s0.65-0.25,0.92-0.36
		c0.26-0.1,0.5-0.15,0.73-0.15c0.35,0,0.67,0.07,0.95,0.22s0.54,0.35,0.75,0.6c0.21,0.25,0.38,0.53,0.5,0.84
		c0.13,0.31,0.19,0.62,0.19,0.92c0,0.72-0.25,1.34-0.75,1.85c-0.5,0.51-1.11,0.93-1.83,1.25c-0.72,0.32-1.48,0.56-2.28,0.71
		c-0.8,0.15-1.48,0.22-2.05,0.22c-1.47,0-2.81-0.26-4.01-0.77s-2.24-1.22-3.1-2.11s-1.52-1.94-2-3.15
		c-0.47-1.21-0.71-2.51-0.71-3.89S162.34,49.72,162.81,48.51z"/>
	<path className="st1" d="M185.65,54.3c0,0.24,0.07,0.52,0.21,0.84c0.14,0.32,0.38,0.6,0.73,0.87c0.35,0.26,0.83,0.49,1.44,0.67
		c0.61,0.18,1.38,0.27,2.3,0.27c0.72,0,1.38-0.09,1.96-0.28c0.58-0.19,1.12-0.4,1.6-0.63c0.49-0.24,0.93-0.45,1.33-0.63
		c0.4-0.19,0.76-0.28,1.08-0.28c0.65,0,1.21,0.23,1.7,0.69c0.49,0.46,0.73,1.06,0.73,1.81c0,0.7-0.27,1.34-0.8,1.92
		c-0.54,0.58-1.24,1.08-2.11,1.49c-0.87,0.41-1.85,0.73-2.95,0.95s-2.19,0.34-3.29,0.34c-1.57,0-2.98-0.26-4.24-0.77
		s-2.33-1.22-3.21-2.11c-0.88-0.89-1.56-1.94-2.03-3.15c-0.47-1.21-0.71-2.51-0.71-3.89c0-1.32,0.25-2.57,0.75-3.77
		c0.5-1.2,1.2-2.25,2.11-3.17c0.91-0.92,1.98-1.64,3.23-2.18c1.24-0.54,2.61-0.81,4.11-0.81c1.44,0,2.74,0.27,3.9,0.8
		s2.15,1.23,2.97,2.1c0.82,0.86,1.46,1.87,1.9,3c0.45,1.14,0.67,2.31,0.67,3.52c0,0.97-0.24,1.62-0.71,1.94
		c-0.47,0.32-1.18,0.48-2.13,0.48H185.65z M193.19,50.31c0-0.27-0.09-0.57-0.26-0.91c-0.17-0.34-0.43-0.66-0.77-0.95
		c-0.34-0.29-0.73-0.54-1.19-0.74c-0.46-0.21-0.98-0.31-1.55-0.31c-0.57,0-1.09,0.1-1.55,0.31c-0.46,0.21-0.86,0.45-1.19,0.74
		c-0.34,0.29-0.59,0.6-0.76,0.95c-0.17,0.34-0.26,0.64-0.26,0.91H193.19z"/>
	<path className="st1" d="M200.92,36.58c0-0.84,0.27-1.55,0.8-2.13s1.24-0.87,2.11-0.87c0.87,0,1.57,0.29,2.11,0.87s0.8,1.29,0.8,2.13
		v12.3l4.74-4.5c0.5-0.47,0.99-0.91,1.47-1.31c0.49-0.4,1.09-0.6,1.81-0.6c0.87,0,1.56,0.26,2.07,0.79s0.77,1.14,0.77,1.84
		c0,0.51-0.17,0.97-0.5,1.39c-0.34,0.41-0.85,0.95-1.55,1.61l-3.14,3.03l4.89,6.06c0.3,0.37,0.6,0.77,0.92,1.22
		c0.31,0.45,0.47,0.88,0.47,1.3c0,0.27-0.06,0.56-0.17,0.88c-0.11,0.32-0.29,0.6-0.52,0.86c-0.24,0.26-0.54,0.47-0.9,0.64
		c-0.36,0.17-0.79,0.26-1.29,0.26c-0.67,0-1.21-0.14-1.62-0.41c-0.41-0.27-0.93-0.79-1.55-1.56l-5.82-7.56h-0.08v6.52
		c0,0.84-0.27,1.55-0.8,2.13s-1.24,0.87-2.11,0.87c-0.87,0-1.57-0.29-2.11-0.87s-0.8-1.29-0.8-2.13V36.58z"/>
	<path className="st4" d="M219.19,35.69c0.19-0.41,0.44-0.78,0.75-1.1c0.31-0.32,0.68-0.57,1.1-0.75s0.87-0.26,1.34-0.26
		c0.47,0,0.92,0.09,1.34,0.26c0.42,0.17,0.79,0.42,1.1,0.75c0.31,0.32,0.56,0.69,0.75,1.1c0.19,0.41,0.28,0.87,0.28,1.36
		c0,0.47-0.09,0.92-0.28,1.34c-0.19,0.42-0.44,0.8-0.75,1.12c-0.31,0.32-0.68,0.57-1.1,0.75c-0.42,0.17-0.87,0.26-1.34,0.26
		c-0.47,0-0.92-0.09-1.34-0.26c-0.42-0.17-0.79-0.42-1.1-0.75c-0.31-0.32-0.56-0.7-0.75-1.12c-0.19-0.42-0.28-0.87-0.28-1.34
		C218.91,36.56,219,36.1,219.19,35.69z M219.47,45.42c0-0.83,0.27-1.53,0.8-2.1c0.54-0.57,1.24-0.86,2.11-0.86
		c0.87,0,1.57,0.29,2.11,0.86c0.54,0.57,0.8,1.27,0.8,2.1v13.94c0,0.83-0.27,1.53-0.8,2.1c-0.54,0.57-1.24,0.86-2.11,0.86
		c-0.87,0-1.57-0.29-2.11-0.86c-0.54-0.57-0.8-1.27-0.8-2.1V45.42z"/>
	<path className="st4" d="M234.7,47.84h-5.11c-0.95,0-1.69-0.21-2.24-0.63c-0.55-0.42-0.82-1.11-0.82-2.05c0-0.95,0.27-1.63,0.82-2.05
		c0.55-0.42,1.29-0.63,2.24-0.63h11.2c0.55,0,0.98,0.05,1.31,0.15c0.32,0.1,0.62,0.29,0.9,0.56c0.35,0.35,0.52,0.81,0.52,1.38
		c0,0.27-0.06,0.54-0.19,0.78c-0.13,0.25-0.31,0.55-0.56,0.9l-7.99,10.71h6.16c0.95,0,1.69,0.21,2.24,0.63
		c0.55,0.42,0.82,1.11,0.82,2.05c0,0.95-0.27,1.63-0.82,2.05c-0.55,0.42-1.29,0.63-2.24,0.63h-11.8c-0.55,0-0.94-0.03-1.18-0.09
		c-0.24-0.06-0.49-0.17-0.76-0.32c-0.37-0.25-0.61-0.54-0.71-0.86c-0.1-0.32-0.15-0.58-0.15-0.78c0-0.22,0.04-0.49,0.13-0.8
		c0.09-0.31,0.34-0.77,0.77-1.36L234.7,47.84z"/>
	<path className="st4" d="M245.04,36.58c0-0.84,0.27-1.55,0.8-2.13s1.24-0.87,2.11-0.87s1.57,0.29,2.11,0.87
		c0.53,0.58,0.8,1.29,0.8,2.13v22.74c0,0.84-0.27,1.55-0.8,2.13c-0.54,0.58-1.24,0.87-2.11,0.87s-1.57-0.29-2.11-0.87
		s-0.8-1.29-0.8-2.13V36.58z"/>
	<path className="st4" d="M253.05,35.69c0.19-0.41,0.43-0.78,0.75-1.1c0.31-0.32,0.68-0.57,1.1-0.75c0.42-0.17,0.87-0.26,1.34-0.26
		c0.47,0,0.92,0.09,1.34,0.26c0.42,0.17,0.79,0.42,1.1,0.75c0.31,0.32,0.56,0.69,0.75,1.1c0.19,0.41,0.28,0.87,0.28,1.36
		c0,0.47-0.09,0.92-0.28,1.34c-0.19,0.42-0.44,0.8-0.75,1.12c-0.31,0.32-0.68,0.57-1.1,0.75c-0.42,0.17-0.87,0.26-1.34,0.26
		c-0.47,0-0.92-0.09-1.34-0.26c-0.42-0.17-0.79-0.42-1.1-0.75s-0.56-0.7-0.75-1.12c-0.19-0.42-0.28-0.87-0.28-1.34
		C252.77,36.56,252.86,36.1,253.05,35.69z M253.33,45.42c0-0.83,0.27-1.53,0.8-2.1c0.54-0.57,1.24-0.86,2.11-0.86
		c0.87,0,1.57,0.29,2.11,0.86c0.53,0.57,0.8,1.27,0.8,2.1v13.94c0,0.83-0.27,1.53-0.8,2.1c-0.54,0.57-1.24,0.86-2.11,0.86
		c-0.87,0-1.57-0.29-2.11-0.86c-0.54-0.57-0.8-1.27-0.8-2.1V45.42z"/>
	<path className="st4" d="M266.58,60.5l-4.96-11.74c-0.35-0.79-0.64-1.5-0.88-2.13c-0.24-0.63-0.35-1.12-0.35-1.46
		c0-0.86,0.29-1.53,0.88-2c0.58-0.47,1.24-0.7,1.96-0.7c1.42,0,2.41,0.73,2.99,2.19l3.43,8.81l3.25-7.85
		c0.37-0.89,0.8-1.64,1.27-2.24c0.47-0.61,1.11-0.91,1.9-0.91c0.9,0,1.62,0.27,2.17,0.8c0.55,0.53,0.82,1.24,0.82,2.13
		c0,0.42-0.08,0.86-0.24,1.31c-0.16,0.46-0.32,0.86-0.47,1.2l-8.62,19.96c-0.17,0.37-0.34,0.72-0.5,1.06
		c-0.16,0.33-0.36,0.62-0.58,0.87s-0.5,0.44-0.84,0.59s-0.75,0.22-1.25,0.22c-0.52,0-0.96-0.09-1.33-0.28
		c-0.36-0.19-0.66-0.41-0.9-0.67c-0.24-0.26-0.4-0.54-0.5-0.83c-0.1-0.3-0.15-0.57-0.15-0.81c0-0.49,0.08-0.96,0.22-1.39
		c0.15-0.43,0.4-1.03,0.75-1.8L266.58,60.5z"/>
	<path className="st4" d="M293.43,43.24c1.21,0.52,2.24,1.22,3.1,2.11c0.86,0.89,1.52,1.94,2,3.15c0.47,1.21,0.71,2.51,0.71,3.89
		s-0.24,2.68-0.71,3.89c-0.47,1.21-1.14,2.26-2,3.15s-1.89,1.6-3.1,2.11s-2.54,0.77-4.01,0.77s-2.81-0.26-4.01-0.77
		s-2.24-1.22-3.1-2.11s-1.52-1.94-2-3.15c-0.47-1.21-0.71-2.51-0.71-3.89s0.24-2.68,0.71-3.89c0.47-1.21,1.14-2.26,2-3.15
		c0.86-0.89,1.89-1.6,3.1-2.11c1.21-0.52,2.54-0.77,4.01-0.77S292.23,42.73,293.43,43.24z M287.7,48.41
		c-0.5,0.23-0.91,0.54-1.25,0.93s-0.59,0.85-0.77,1.38c-0.17,0.52-0.26,1.08-0.26,1.67s0.09,1.15,0.26,1.67
		c0.17,0.53,0.43,0.98,0.77,1.38s0.75,0.71,1.25,0.93c0.5,0.23,1.07,0.34,1.72,0.34s1.22-0.12,1.72-0.34
		c0.5-0.23,0.91-0.54,1.25-0.93s0.59-0.85,0.77-1.38c0.17-0.52,0.26-1.08,0.26-1.67s-0.09-1.15-0.26-1.67
		c-0.17-0.53-0.43-0.98-0.77-1.38s-0.75-0.71-1.25-0.93c-0.5-0.23-1.07-0.34-1.72-0.34S288.2,48.18,287.7,48.41z"/>
	<path className="st4" d="M301.14,45.42c0-0.83,0.27-1.53,0.8-2.1c0.54-0.57,1.24-0.86,2.11-0.86c0.8,0,1.43,0.24,1.9,0.71
		c0.47,0.47,0.81,0.95,1.01,1.44c0.5-0.55,1.09-1.05,1.77-1.49c0.68-0.44,1.44-0.66,2.26-0.66c0.75,0,1.43,0.26,2.05,0.79
		c0.62,0.53,0.93,1.23,0.93,2.11c0,0.48-0.09,0.88-0.28,1.2c-0.19,0.32-0.44,0.59-0.76,0.81c-0.32,0.21-0.71,0.4-1.16,0.56
		s-0.93,0.3-1.46,0.45c-0.62,0.17-1.15,0.35-1.59,0.56c-0.44,0.2-0.78,0.45-1.05,0.75c-0.26,0.3-0.45,0.68-0.56,1.15
		s-0.17,1.06-0.17,1.77v6.81c0,0.81-0.27,1.5-0.8,2.06c-0.54,0.56-1.24,0.84-2.11,0.84c-0.87,0-1.57-0.29-2.11-0.86
		c-0.54-0.57-0.8-1.27-0.8-2.1V45.42z"/>
	<path className="st4" d="M332.35,54.87c0,1.29-0.24,2.4-0.73,3.33c-0.49,0.93-1.14,1.7-1.96,2.31s-1.78,1.06-2.87,1.36
		c-1.1,0.3-2.25,0.45-3.47,0.45c-1.22,0-2.38-0.15-3.47-0.45c-1.1-0.3-2.05-0.75-2.87-1.36s-1.47-1.38-1.96-2.31
		c-0.49-0.93-0.73-2.04-0.73-3.33v-9.5c0-0.81,0.27-1.5,0.8-2.06c0.53-0.56,1.24-0.84,2.11-0.84s1.57,0.28,2.11,0.83
		c0.53,0.55,0.8,1.23,0.8,2.03v8.58c0,0.52,0.09,0.97,0.28,1.36c0.19,0.39,0.44,0.71,0.75,0.95c0.31,0.25,0.66,0.43,1.04,0.55
		s0.77,0.18,1.14,0.18s0.75-0.06,1.14-0.18c0.38-0.12,0.73-0.3,1.04-0.55c0.31-0.25,0.56-0.57,0.75-0.95
		c0.19-0.39,0.28-0.84,0.28-1.36v-8.58c0-0.8,0.27-1.48,0.8-2.03c0.53-0.55,1.24-0.83,2.11-0.83s1.57,0.28,2.11,0.84
		s0.8,1.25,0.8,2.06V54.87z"/>
	<path className="st4" d="M341.87,47.84h-5.11c-0.95,0-1.69-0.21-2.24-0.63c-0.55-0.42-0.82-1.11-0.82-2.05c0-0.95,0.27-1.63,0.82-2.05
		c0.55-0.42,1.29-0.63,2.24-0.63h11.2c0.55,0,0.98,0.05,1.31,0.15c0.32,0.1,0.62,0.29,0.9,0.56c0.35,0.35,0.52,0.81,0.52,1.38
		c0,0.27-0.06,0.54-0.19,0.78c-0.13,0.25-0.31,0.55-0.56,0.9l-7.99,10.71h6.16c0.95,0,1.69,0.21,2.24,0.63
		c0.55,0.42,0.82,1.11,0.82,2.05c0,0.95-0.27,1.63-0.82,2.05c-0.55,0.42-1.29,0.63-2.24,0.63h-11.8c-0.55,0-0.94-0.03-1.18-0.09
		c-0.24-0.06-0.49-0.17-0.76-0.32c-0.37-0.25-0.61-0.54-0.71-0.86c-0.1-0.32-0.15-0.58-0.15-0.78c0-0.22,0.04-0.49,0.13-0.8
		c0.09-0.31,0.34-0.77,0.77-1.36L341.87,47.84z"/>
	<path className="st2" d="M353.18,57.83c0.17-0.39,0.41-0.73,0.71-1.03s0.64-0.54,1.03-0.71s0.8-0.26,1.25-0.26
		c0.45,0,0.86,0.09,1.25,0.26s0.73,0.41,1.03,0.71c0.3,0.3,0.54,0.64,0.71,1.03c0.17,0.39,0.26,0.8,0.26,1.25
		c0,0.45-0.09,0.87-0.26,1.27c-0.17,0.4-0.41,0.74-0.71,1.03c-0.3,0.29-0.64,0.52-1.03,0.69c-0.39,0.17-0.8,0.26-1.25,0.26
		c-0.45,0-0.87-0.09-1.25-0.26c-0.39-0.17-0.73-0.4-1.03-0.69c-0.3-0.29-0.54-0.63-0.71-1.03c-0.17-0.4-0.26-0.82-0.26-1.27
		C352.91,58.63,353,58.21,353.18,57.83z"/>
	<path className="st2" d="M362.62,48.51c0.47-1.21,1.14-2.26,2-3.15c0.86-0.89,1.89-1.6,3.1-2.11c1.21-0.52,2.54-0.77,4.01-0.77
		c0.65,0,1.37,0.06,2.18,0.17c0.81,0.11,1.56,0.31,2.26,0.6s1.28,0.68,1.75,1.18c0.47,0.5,0.71,1.13,0.71,1.9
		c0,0.8-0.23,1.45-0.69,1.96c-0.46,0.51-1.03,0.77-1.7,0.77c-0.37,0-0.7-0.05-0.97-0.15c-0.27-0.1-0.55-0.21-0.84-0.34
		c-0.29-0.13-0.61-0.24-0.97-0.34c-0.36-0.1-0.83-0.15-1.4-0.15c-0.75,0-1.39,0.11-1.92,0.34c-0.54,0.23-0.98,0.54-1.34,0.93
		s-0.63,0.85-0.8,1.38c-0.17,0.52-0.26,1.08-0.26,1.67s0.09,1.15,0.26,1.67c0.17,0.53,0.43,0.98,0.77,1.38s0.75,0.71,1.25,0.93
		c0.5,0.23,1.07,0.34,1.72,0.34c0.62,0,1.17-0.05,1.64-0.15c0.47-0.1,0.88-0.22,1.23-0.35c0.35-0.14,0.65-0.25,0.91-0.36
		c0.26-0.1,0.5-0.15,0.73-0.15c0.35,0,0.67,0.07,0.95,0.22s0.54,0.35,0.75,0.6c0.21,0.25,0.38,0.53,0.5,0.84
		c0.12,0.31,0.19,0.62,0.19,0.92c0,0.72-0.25,1.34-0.75,1.85c-0.5,0.51-1.11,0.93-1.83,1.25c-0.72,0.32-1.48,0.56-2.28,0.71
		c-0.8,0.15-1.48,0.22-2.05,0.22c-1.47,0-2.81-0.26-4.01-0.77c-1.21-0.52-2.24-1.22-3.1-2.11s-1.52-1.94-2-3.15
		s-0.71-2.51-0.71-3.89S362.15,49.72,362.62,48.51z"/>
	<path className="st2" d="M393.02,43.24c1.21,0.52,2.24,1.22,3.1,2.11c0.86,0.89,1.52,1.94,2,3.15s0.71,2.51,0.71,3.89
		s-0.24,2.68-0.71,3.89s-1.14,2.26-2,3.15s-1.89,1.6-3.1,2.11c-1.21,0.52-2.54,0.77-4.01,0.77c-1.47,0-2.81-0.26-4.01-0.77
		c-1.21-0.52-2.24-1.22-3.1-2.11s-1.52-1.94-2-3.15s-0.71-2.51-0.71-3.89s0.24-2.68,0.71-3.89s1.14-2.26,2-3.15
		c0.86-0.89,1.89-1.6,3.1-2.11c1.21-0.52,2.54-0.77,4.01-0.77C390.48,42.47,391.81,42.73,393.02,43.24z M387.29,48.41
		c-0.5,0.23-0.92,0.54-1.25,0.93s-0.59,0.85-0.77,1.38c-0.17,0.52-0.26,1.08-0.26,1.67s0.09,1.15,0.26,1.67
		c0.17,0.53,0.43,0.98,0.77,1.38s0.75,0.71,1.25,0.93c0.5,0.23,1.07,0.34,1.72,0.34c0.65,0,1.22-0.12,1.72-0.34
		c0.5-0.23,0.91-0.54,1.25-0.93c0.34-0.39,0.59-0.85,0.77-1.38c0.17-0.52,0.26-1.08,0.26-1.67s-0.09-1.15-0.26-1.67
		c-0.17-0.53-0.43-0.98-0.77-1.38c-0.34-0.39-0.75-0.71-1.25-0.93c-0.5-0.23-1.07-0.34-1.72-0.34
		C388.36,48.07,387.79,48.18,387.29,48.41z"/>
	<path className="st2" d="M400.73,45.42c0-0.83,0.27-1.53,0.8-2.1c0.53-0.57,1.24-0.86,2.11-0.86c0.8,0,1.43,0.25,1.9,0.74
		c0.47,0.49,0.81,0.99,1.01,1.5c0.47-0.72,1.16-1.28,2.07-1.66c0.91-0.39,1.88-0.58,2.93-0.58c0.85,0,1.78,0.17,2.8,0.52
		c1.02,0.35,1.89,0.97,2.61,1.87c0.35-0.27,0.72-0.55,1.12-0.82c0.4-0.27,0.83-0.53,1.29-0.77c0.46-0.24,0.96-0.43,1.49-0.58
		s1.11-0.22,1.74-0.22c1.02,0,1.97,0.13,2.84,0.38s1.64,0.65,2.3,1.18c0.66,0.54,1.18,1.22,1.55,2.06c0.37,0.84,0.56,1.83,0.56,2.98
		v10.36c0,0.81-0.27,1.5-0.8,2.06c-0.54,0.56-1.24,0.84-2.11,0.84s-1.57-0.28-2.11-0.83c-0.54-0.55-0.8-1.23-0.8-2.04v-8.74
		c0-0.87-0.25-1.64-0.75-2.32c-0.5-0.67-1.22-1.01-2.17-1.01c-0.95,0-1.67,0.34-2.17,1.01c-0.5,0.67-0.75,1.45-0.75,2.32v8.74
		c0,0.8-0.27,1.48-0.8,2.04s-1.24,0.83-2.11,0.83c-0.87,0-1.57-0.28-2.11-0.83s-0.8-1.23-0.8-2.04v-8.74c0-0.87-0.25-1.64-0.75-2.32
		c-0.5-0.67-1.22-1.01-2.17-1.01c-0.95,0-1.67,0.34-2.17,1.01c-0.5,0.67-0.75,1.45-0.75,2.32v8.74c0,0.8-0.27,1.48-0.8,2.04
		c-0.54,0.55-1.24,0.83-2.11,0.83s-1.57-0.29-2.11-0.86c-0.54-0.57-0.8-1.27-0.8-2.1V45.42z"/>
</g>
<g>
	<path className="st7" d="M110.48,86.38c-0.82,0.81-1.81,1.17-2.87,1.19l-0.16,0.68c1.32-0.05,1.7,0.89,1.52,1.65
		c-0.21,0.84-1.01,1.21-1.79,1.19c-0.68,0-1.35-0.3-1.71-0.89l0.54-0.49c0.3,0.4,0.63,0.55,1.06,0.57c0.46,0.05,0.94-0.11,1.06-0.54
		c0.17-0.59-0.43-1.13-1.46-0.82l-0.19-0.14l0.32-1.27c-1.85-0.3-3.28-1.73-3.28-4c0-2.57,1.79-4.04,4.06-4.04
		c1.06,0,1.97,0.35,2.77,1.16l-0.7,0.68c-0.59-0.57-1.3-0.84-2.06-0.84c-1.66,0-3.01,1.11-3.01,3.04c0,1.98,1.36,3.04,3,3.04
		c0.81,0,1.58-0.3,2.17-0.89L110.48,86.38z"/>
	<path className="st7" d="M120.18,83.54c0,2.43-1.7,4.03-4.03,4.03c-2.28,0-4.03-1.6-4.03-4.03s1.68-4.07,4.03-4.07
		C118.43,79.46,120.18,81.11,120.18,83.54z M113.19,83.54c0,1.84,1.24,3.03,2.96,3.03c1.76,0,2.96-1.19,2.96-3.03
		c0-1.82-1.24-3.11-2.96-3.11C114.41,80.43,113.19,81.71,113.19,83.54z"/>
	<path className="st7" d="M123.89,76.31v11.1h-1.06v-11.1H123.89z"/>
	<path className="st7" d="M128.09,79.57v4.39c0,1.65,0.89,2.57,2.47,2.55c1.51,0,2.57-1.17,2.57-2.68v-4.26h1.05v7.83h-0.98l-0.03-1.32
		c-0.65,1.01-1.68,1.46-2.77,1.47c-2.03,0-3.36-1.38-3.36-3.58v-4.41H128.09z"/>
	<path className="st7" d="M143.97,79.1l0.73,0.6l-0.84,1c0.59,0.65,1.05,1.35,1.05,2.6c0,1.38-0.76,2.82-2.36,3.28
		c1.47,0.4,2.31,1.25,2.31,2.57c0,1.97-1.95,2.98-4.03,2.98c-2.25,0-3.96-1.19-3.96-3.23h1.06c0,1.47,1.4,2.2,2.9,2.2
		c1.44,0,2.95-0.56,2.95-1.95c0-1.38-1.49-2-2.95-2c-2.41,0-4.11-1.41-4.11-3.85c0-2.55,2.14-3.84,4.11-3.84
		c0.76,0,1.71,0.19,2.31,0.62L143.97,79.1z M137.81,83.3c0,1.95,1.33,2.87,3.03,2.87c1.82,0,3.01-1.3,3.01-2.87
		c0-1.95-1.47-2.85-3.01-2.85C139.39,80.45,137.81,81.33,137.81,83.3z M143.07,76.15c0.05,1.33-0.79,2.04-2.11,2.04
		c-1.27,0-2.12-0.73-2.08-2.04h0.86c0,0.57,0.25,1.22,1.22,1.22c0.9,0,1.22-0.6,1.22-1.22H143.07z"/>
	<path className="st7" d="M148.52,79.57v4.39c0,1.65,0.89,2.57,2.47,2.55c1.51,0,2.57-1.17,2.57-2.68v-4.26h1.05v7.83h-0.98l-0.03-1.32
		c-0.65,1.01-1.68,1.46-2.77,1.47c-2.03,0-3.36-1.38-3.36-3.58v-4.41H148.52z"/>
	<path className="st7" d="M163.31,87.41v-4.53c0-1.38-0.92-2.44-2.3-2.44s-2.3,1.06-2.3,2.44v4.53h-1.06v-7.86h1l0.05,1.25
		c0.51-1,1.47-1.33,2.43-1.33c1.14,0,2.28,0.43,2.76,1.78c0.52-1.25,1.66-1.78,2.82-1.78c1.98,0,3.3,1.28,3.3,3.38v4.57h-1.06v-4.57
		c0-1.39-0.87-2.41-2.25-2.41s-2.31,1.06-2.31,2.44v4.53H163.31z"/>
	<path className="st7" d="M174,79.57v4.39c0,1.65,0.89,2.57,2.47,2.55c1.51,0,2.57-1.17,2.57-2.68v-4.26h1.05v7.83h-0.98l-0.03-1.32
		c-0.65,1.01-1.68,1.46-2.77,1.47c-2.03,0-3.36-1.38-3.36-3.58v-4.41H174z"/>
	<path className="st7" d="M188.63,79.57v0.46L184,86.39h4.65v1.01h-6.26v-0.4l4.68-6.47h-4.41v-0.97H188.63z"/>
	<path className="st7" d="M202.4,79.57l-3.3,7.85h-1.17l-3.3-7.85h1.17l2.71,6.67l2.71-6.67H202.4z"/>
	<path className="st7" d="M210.96,79.57h1v7.83h-0.98v-1.55c-0.68,1.16-1.97,1.71-3.14,1.71c-2.22,0-4-1.58-4-4.07
		c0-2.46,1.79-4.03,4.01-4.03c1.21,0,2.5,0.55,3.11,1.66V79.57z M204.91,83.49c0,1.84,1.32,3.08,2.98,3.08c4.04,0,4.04-6.14,0-6.14
		C206.22,80.43,204.91,81.65,204.91,83.49z"/>
	<path className="st7" d="M215.97,79.57l0.06,1.28c0.51-1.06,1.54-1.41,2.49-1.41c0.71,0,1.21,0.16,1.78,0.56l-0.49,0.89
		c-0.4-0.27-0.86-0.41-1.35-0.41c-1.35,0-2.43,1.05-2.43,2.47v4.46h-1.06v-7.83H215.97z"/>
	<path className="st7" d="M232.94,86.38c-0.82,0.81-1.81,1.17-2.87,1.19l-0.16,0.68c1.32-0.05,1.7,0.89,1.52,1.65
		c-0.21,0.84-1.01,1.21-1.79,1.19c-0.68,0-1.35-0.3-1.71-0.89l0.54-0.49c0.3,0.4,0.63,0.55,1.06,0.57c0.46,0.05,0.93-0.11,1.06-0.54
		c0.17-0.59-0.43-1.13-1.46-0.82l-0.19-0.14l0.32-1.27c-1.86-0.3-3.28-1.73-3.28-4c0-2.57,1.79-4.04,4.06-4.04
		c1.06,0,1.97,0.35,2.77,1.16l-0.7,0.68c-0.59-0.57-1.3-0.84-2.06-0.84c-1.66,0-3.01,1.11-3.01,3.04c0,1.98,1.36,3.04,3,3.04
		c0.81,0,1.58-0.3,2.17-0.89L232.94,86.38z"/>
	<path className="st7" d="M242.64,83.54c0,2.43-1.7,4.03-4.03,4.03c-2.28,0-4.03-1.6-4.03-4.03s1.68-4.07,4.03-4.07
		C240.89,79.46,242.64,81.11,242.64,83.54z M235.65,83.54c0,1.84,1.24,3.03,2.96,3.03c1.76,0,2.96-1.19,2.96-3.03
		c0-1.82-1.24-3.11-2.96-3.11C236.87,80.43,235.65,81.71,235.65,83.54z"/>
	<path className="st7" d="M251.63,86.38c-0.82,0.81-1.82,1.19-2.9,1.19c-2.22,0-4.06-1.49-4.06-4.06s1.79-4.04,4.06-4.04
		c1.06,0,1.97,0.35,2.77,1.16l-0.7,0.68c-0.59-0.57-1.3-0.84-2.06-0.84c-1.67,0-3.01,1.11-3.01,3.04c0,1.98,1.36,3.04,3,3.04
		c0.81,0,1.58-0.3,2.17-0.89L251.63,86.38z"/>
	<path className="st7" d="M254.97,79.57v4.39c0,1.65,0.89,2.57,2.47,2.55c1.51,0,2.57-1.17,2.57-2.68v-4.26h1.05v7.83h-0.98l-0.03-1.32
		c-0.65,1.01-1.68,1.46-2.77,1.47c-2.03,0-3.36-1.38-3.36-3.58v-4.41H254.97z"/>
	<path className="st7" d="M270.85,79.1l0.73,0.6l-0.84,1c0.59,0.65,1.05,1.35,1.05,2.6c0,1.38-0.76,2.82-2.36,3.28
		c1.47,0.4,2.32,1.25,2.32,2.57c0,1.97-1.95,2.98-4.03,2.98c-2.25,0-3.96-1.19-3.96-3.23h1.06c0,1.47,1.4,2.2,2.9,2.2
		c1.44,0,2.95-0.56,2.95-1.95c0-1.38-1.49-2-2.95-2c-2.41,0-4.11-1.41-4.11-3.85c0-2.55,2.14-3.84,4.11-3.84
		c0.76,0,1.71,0.19,2.31,0.62L270.85,79.1z M264.69,83.3c0,1.95,1.33,2.87,3.03,2.87c1.82,0,3.01-1.3,3.01-2.87
		c0-1.95-1.47-2.85-3.01-2.85C266.27,80.45,264.69,81.33,264.69,83.3z M269.95,76.15c0.05,1.33-0.79,2.04-2.11,2.04
		c-1.27,0-2.13-0.73-2.08-2.04h0.86c0,0.57,0.25,1.22,1.22,1.22c0.9,0,1.22-0.6,1.22-1.22H269.95z"/>
	<path className="st7" d="M275.4,79.57v4.39c0,1.65,0.89,2.57,2.47,2.55c1.51,0,2.57-1.17,2.57-2.68v-4.26h1.05v7.83h-0.98l-0.03-1.32
		c-0.65,1.01-1.68,1.46-2.78,1.47c-2.03,0-3.36-1.38-3.36-3.58v-4.41H275.4z"/>
	<path className="st7" d="M290.19,87.41v-4.53c0-1.38-0.92-2.44-2.3-2.44c-1.38,0-2.3,1.06-2.3,2.44v4.53h-1.06v-7.86h1l0.05,1.25
		c0.51-1,1.47-1.33,2.43-1.33c1.14,0,2.28,0.43,2.76,1.78c0.52-1.25,1.66-1.78,2.82-1.78c1.98,0,3.3,1.28,3.3,3.38v4.57h-1.06v-4.57
		c0-1.39-0.87-2.41-2.25-2.41c-1.38,0-2.31,1.06-2.31,2.44v4.53H290.19z"/>
	<path className="st7" d="M300.88,79.57v4.39c0,1.65,0.89,2.57,2.47,2.55c1.51,0,2.57-1.17,2.57-2.68v-4.26h1.05v7.83h-0.98l-0.03-1.32
		c-0.65,1.01-1.68,1.46-2.78,1.47c-2.03,0-3.36-1.38-3.36-3.58v-4.41H300.88z"/>
	<path className="st7" d="M315.51,79.57v0.46l-4.63,6.36h4.65v1.01h-6.26v-0.4l4.68-6.47h-4.41v-0.97H315.51z"/>
	<path className="st7" d="M329.29,79.57l-3.3,7.85h-1.17l-3.3-7.85h1.17l2.71,6.67l2.71-6.67H329.29z"/>
	<path className="st7" d="M337.85,79.57h1v7.83h-0.98v-1.55c-0.68,1.16-1.97,1.71-3.14,1.71c-2.22,0-4-1.58-4-4.07
		c0-2.46,1.79-4.03,4.01-4.03c1.21,0,2.5,0.55,3.11,1.66V79.57z M331.79,83.49c0,1.84,1.32,3.08,2.98,3.08c4.04,0,4.04-6.14,0-6.14
		C333.11,80.43,331.79,81.65,331.79,83.49z"/>
	<path className="st7" d="M342.85,79.57l0.06,1.28c0.51-1.06,1.54-1.41,2.49-1.41c0.71,0,1.21,0.16,1.78,0.56l-0.49,0.89
		c-0.4-0.27-0.86-0.41-1.35-0.41c-1.35,0-2.43,1.05-2.43,2.47v4.46h-1.06v-7.83H342.85z"/>
	<path className="st7" d="M350.64,86.71c0,1.03-1.57,1.03-1.57,0C349.07,85.66,350.64,85.66,350.64,86.71z M350.38,76.31v8.67h-1.13
		v-8.67H350.38z"/>
</g>
<path className="st6" d="M64.32,48.83L46.16,38.74c-0.14-0.08-0.28-0.15-0.42-0.21c-0.05-0.02-0.09-0.04-0.14-0.06
	c-0.09-0.04-0.19-0.08-0.28-0.12c-0.06-0.02-0.11-0.04-0.17-0.06c-0.09-0.03-0.17-0.06-0.26-0.09c-0.06-0.02-0.12-0.03-0.18-0.05
	c-0.08-0.02-0.16-0.05-0.24-0.06c-0.06-0.01-0.13-0.03-0.19-0.04c-0.08-0.02-0.16-0.03-0.24-0.05c-0.07-0.01-0.13-0.02-0.2-0.03
	c-0.08-0.01-0.15-0.02-0.23-0.03c-0.07-0.01-0.13-0.01-0.2-0.01c-0.07,0-0.15-0.01-0.22-0.01c-0.07,0-0.14,0-0.2,0
	c-0.07,0-0.15,0-0.22,0.01c-0.07,0-0.14,0.01-0.21,0.02c-0.07,0.01-0.14,0.01-0.21,0.02c-0.07,0.01-0.14,0.02-0.21,0.03
	C42.07,38,42,38.01,41.93,38.03c-0.07,0.01-0.14,0.03-0.21,0.04c-0.07,0.01-0.13,0.03-0.2,0.05c-0.07,0.02-0.14,0.04-0.21,0.06
	c-0.06,0.02-0.13,0.04-0.19,0.06c-0.07,0.02-0.14,0.05-0.21,0.07c-0.06,0.02-0.12,0.04-0.18,0.07c-0.07,0.03-0.14,0.06-0.21,0.09
	c-0.06,0.03-0.11,0.05-0.17,0.08c-0.07,0.03-0.14,0.07-0.21,0.1c-0.05,0.03-0.11,0.06-0.16,0.09c-0.07,0.04-0.14,0.08-0.21,0.12
	c-0.05,0.03-0.1,0.06-0.15,0.09c-0.07,0.04-0.14,0.09-0.21,0.14c-0.05,0.03-0.09,0.06-0.14,0.1c-0.07,0.05-0.14,0.1-0.21,0.15
	c-0.04,0.03-0.08,0.07-0.12,0.1c-0.07,0.06-0.14,0.11-0.21,0.17c-0.04,0.03-0.07,0.07-0.11,0.1c-0.07,0.06-0.14,0.13-0.2,0.19
	c-0.03,0.03-0.06,0.07-0.1,0.1c-0.07,0.07-0.14,0.14-0.2,0.22c-0.03,0.03-0.05,0.06-0.08,0.09c-0.07,0.08-0.13,0.16-0.2,0.24
	c-0.02,0.03-0.04,0.06-0.06,0.09c-0.07,0.09-0.13,0.18-0.2,0.27c-0.02,0.02-0.03,0.05-0.05,0.07c-0.07,0.1-0.13,0.2-0.19,0.3
	c-0.01,0.01-0.02,0.03-0.03,0.04c-0.59,0.99-0.97,2.15-1.06,3.44c0,0,0,0,0,0c-0.01,0.19-0.02,0.38-0.02,0.58V53v0c0,0,0,0,0,0v1.21
	c0,0,0,0,0,0v11.3c0,0.17,0.01,0.33,0.02,0.5c0,0.05,0.01,0.11,0.01,0.16c0.01,0.11,0.02,0.22,0.03,0.32
	c0.01,0.06,0.02,0.13,0.03,0.19c0.01,0.09,0.03,0.19,0.04,0.28c0.01,0.07,0.03,0.13,0.04,0.2c0.02,0.09,0.04,0.18,0.06,0.26
	c0.02,0.07,0.03,0.13,0.05,0.2c0.02,0.08,0.05,0.17,0.07,0.25c0.02,0.07,0.04,0.13,0.06,0.2c0.03,0.08,0.05,0.16,0.08,0.24
	c0.02,0.07,0.05,0.13,0.08,0.19c0.03,0.08,0.06,0.15,0.09,0.22c0.03,0.06,0.06,0.13,0.09,0.19c0.03,0.07,0.07,0.14,0.11,0.21
	c0.03,0.06,0.06,0.12,0.1,0.19c0.04,0.07,0.08,0.13,0.11,0.2c0.04,0.06,0.07,0.12,0.11,0.18c0.04,0.06,0.08,0.12,0.12,0.19
	c0.04,0.06,0.08,0.12,0.12,0.18c0.04,0.06,0.09,0.11,0.13,0.17c0.04,0.06,0.09,0.12,0.13,0.17c0.04,0.05,0.09,0.1,0.13,0.15
	c0.05,0.06,0.1,0.12,0.15,0.17c0.04,0.04,0.08,0.08,0.12,0.13c0.06,0.06,0.11,0.12,0.17,0.18c0,0,0.01,0,0.01,0.01
	c0.18,0.18,0.37,0.34,0.57,0.49c0.01,0.01,0.03,0.02,0.04,0.03c0.08,0.06,0.16,0.12,0.24,0.17c0.03,0.02,0.06,0.05,0.09,0.07
	c0.08,0.05,0.16,0.1,0.24,0.15c0.04,0.02,0.07,0.05,0.11,0.07c0.08,0.05,0.16,0.09,0.24,0.14c0.04,0.02,0.08,0.04,0.11,0.06
	c0.08,0.04,0.17,0.08,0.25,0.12c0.04,0.02,0.08,0.04,0.12,0.05c0.09,0.04,0.17,0.07,0.26,0.11c0.04,0.01,0.08,0.03,0.12,0.04
	c0.09,0.03,0.18,0.06,0.27,0.09c0.04,0.01,0.08,0.02,0.11,0.03c0.09,0.03,0.19,0.05,0.28,0.08c0.04,0.01,0.07,0.02,0.11,0.03
	c0.1,0.02,0.19,0.04,0.29,0.06c0.03,0.01,0.07,0.01,0.1,0.02c0.1,0.02,0.2,0.03,0.31,0.04c0.03,0,0.06,0.01,0.08,0.01
	c0.11,0.01,0.22,0.02,0.33,0.02c0.02,0,0.04,0,0.06,0c0.29,0.01,0.59,0,0.89-0.03c0.46-0.05,0.92-0.15,1.38-0.31
	c0.04-0.01,0.08-0.03,0.12-0.04c0.31-0.12,0.62-0.26,0.93-0.43l18.16-10.09C69.27,59.26,69.27,51.58,64.32,48.83z"/>

</svg>

  );
};

// Logo.defaultProps = {
//   viewBox: '0 0 240 240'
// };

export default Logo;