import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  root: {
    // width: 300,
    paddingLeft: '6px'
  },
  number: {
    width: 40
  }
});

export default function MySlider(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props);

  useEffect(() => {
    const { value } = props;
    setValue(props);
  }, [value]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSliderChangeCommitted = (event, newValue) => {
    props.onChange(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-small-steps" gutterBottom>
        {props.title}
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs>
          <Slider
            defaultValue={0.0}
            aria-labelledby="discrete-slider-small-steps"
            step={0.5}
            marks
            min={0}
            max={10}
            valueLabelDisplay="auto"
            value={typeof props.value === 'number' ? props.value : 0}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommitted}
          />
        </Grid>
        <Grid item className={classes.number}>
          <Typography component="legend">{props.value}</Typography>
        </Grid>
      </Grid>
    </div>
  );
}