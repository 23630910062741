export default {
    homeHeaderContent: `Siz de izleyeceğiniz filmi seçerken film süresinden daha çok zaman harcıyor ve film sonunda pişmanlık mı yaşıyorsunuz?
    Ailecek izliyoruz ile sizlere bir tarama sonucu sunuyoruz.
    Sitemizde mevcut puanlama ile filmlerde aradığınız kriterleri bulabilir, istemediğiniz içeriklerden  kaçınabilir ve ailecek kaliteli bir film keyfi yaşayabilirsiniz.
    Iyi seyirler...😊`,
    homeHeader: "Ailecek İzliyoruz",
    addFilmAddDescription1: "Film eklemek için ",
    addFilmAddDescription2: " sitesinde filmi açın ve adres çubuğunda",
    addFilmAddDescription3: " ile başlayan film kodunu aşağıdaki alana yapıştırın. Ardından",
    addFilmAddDescription4: " tuşuna basın.",
    addFilmFilmCode: "Film Kodu",
    addFilmFilmName: "Film Adı",
    addFilmFilmNameTR: "Türkçe Film  Adı",
    addFilmDescription: "Açıklama",
    addFilmDescriptionTR: "Türkçe Açıklama",
    addFilmPosterLink: "Poster Linki",
    addFilmViolence: "Şiddet",
    addFilmObscene: "Müstehcenlik",
    addFilmSwearing: "Küfür",
    addFilmDrug: "Yabancı Madde Kullanımı",
    addFilmHorror: "Korkunç ve Aşırı Sahneler",
    addFilmFilmWarning: "Film Uyarıları",
    addFilmLabel: "Etiket",
    addFilmStartTime: "Başlangıç Zamanı",
    addFilmEndTime: "Bitiş Zamanı",
    addFilmYear: "Yayınlanma Yılı",
    addFilmTime: "Süre (dakika)",
    addFilmCountry: "Ülke",
    addFilmDirector: "Direktör",
    addFilmActor: "Aktör(ler)",
    addFilmLanguage: "Orijinal Dili",
    addFilmIMDB: "IMDB Puanı",
    addFilmAddFilm: "Film Ekle", 
    addFilmAddFilmWarning: "Film Uyarısı Ekle",
    addFilmOk: "Tamam",
    addFilmCancel: "İptal",
    filmListFilmList: 'Film Listesi',
    filmListFilmName: 'Film Adı',
    filmListPublished: 'Yayın Durumu',
    filmListFilmRateCount: 'Değerlendiren Kişi Sayısı',
    filmListRateAI: "Aİ Puanı"
}




