import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export default function LoadingSkeleton() {

	const SkeletonItem = (count) => {
		let returnObject = [];
		let size = 12/count;
		for (let i = 0; i < count; i++) {
			returnObject.push(
				<Grid item xs={12} sm={size} md={size} >
					<Skeleton variant="rect" height={150} key={i + 1} />
					<Skeleton variant="text" key={i + 2} />
					<Skeleton variant="text" key={i + 3} />
					<Skeleton variant="text" key={i + 4} />
					<Skeleton variant="text" key={i + 5} />
					<Skeleton variant="text" key={i + 6} />
				</Grid>
			);
		}
		return returnObject;
	}

	return (
		<Grid container spacing={4}>
			{SkeletonItem(4)}
		</Grid>
	)
}