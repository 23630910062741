const initialState = {}

export const MainReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, ...action.payload };
    case 'MESSAGE':
      state.message = { message: '', severity: '' }
      return { ...state, ...action.payload };
    default:
      return state;
  }
}