
import { combineReducers } from 'redux';
import { LoginReducer } from './LoginReducer';
import { MenuReducer } from './MenuReducer';
import { MainReducer } from './MainReducer';

const RootReducer = combineReducers({
    main: MainReducer,
    user: LoginReducer,
    menu: MenuReducer
});

export default RootReducer;