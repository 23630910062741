import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'

// const useStyles = makeStyles((theme) => ({
//   heroContent: {
//     backgroundColor: theme.palette.background.paper,
//     padding: theme.spacing(2, 0, 2),
//   }
// }));

export default function HeroContent({ children }) {
  // const classes = useStyles();
  return (
    // <div className={classes.heroContent}>
      <Stack>
      {children}

      </Stack>
    // </div>
  )
}
