import { EditingState, IntegratedPaging, PagingState, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, PagingPanel, Table, TableEditColumn, TableEditRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { setMessage } from '../../redux/actions/MainAction';
import AdminService from '../../services/AdminService';
import { useNavigate } from 'react-router-dom';


function Role(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [roleList, setRoleList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageSizes] = useState([20, 50, 100]);

  const getRowId = row => row.id;

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    AdminService().RoleList()
      .then((response) => {
        setRoleList(response);
      })
  }, [user, currentPage, pageSize]);

  const commitChanges = ({ added, changed }) => {
    let changedRows;
    if (added) {
      const startingAddedId = roleList.length > 0 ? roleList[roleList.length - 1].id + 1 : 0;
      changedRows = [
        ...roleList,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
      AdminService().SaveRole(added[0])
        .then(() => {
          dispatch(setMessage({ message: 'Yeni rol tanımı kaydedildi', severity: 'success' }));
          setRoleList(changedRows);
        })
    }
    if (changed) {
      changedRows = roleList.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
      let changedRole = changedRows.find(row => changed[row.id])
      AdminService().SaveRole(changedRole)
        .then(() => {
          dispatch(setMessage({ message: 'Rol tanımı güncellendi', severity: 'success' }));
          setRoleList(changedRows);
        })
    }
    setRoleList(changedRows);
  };

  return (
    <BasePage maxWidth="sm">
      <HeroContent>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>{'Roller'}</Typography>
        </Container>
      </HeroContent>

      <Container className={classes.container} maxWidth="sm">
          <Paper variant="outlined" className={classes.paper}>
            <DxGrid
              rows={roleList}
              columns={[
                { name: 'roleName', title: 'Role Adı' },
              ]}
              getRowId={getRowId}>
              <EditingState onCommitChanges={commitChanges} />
              <RowDetailState />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={(p) => {
                  setCurrentPage(0);
                  setPageSize(p);
                }}
              />
              <IntegratedPaging />
              <Table />
              <TableHeaderRow />
              <TableEditRow />
              <TableEditColumn showAddCommand showEditCommand messages={{ addCommand: 'Yeni Ekle', editCommand: 'Düzenle', commitCommand: 'Kaydet', cancelCommand: 'İptal' }} />
              <PagingPanel pageSizes={pageSizes} messages={{ rowsPerPage: 'Kayıt Sayısı:' }} />
            </DxGrid>
          </Paper>
      </Container>

    </BasePage>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  box: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    padding: theme.spacing(2)
  },
}));

export default Role;