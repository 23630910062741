import { CustomPaging, FilteringState, PagingState, RowDetailState, SortingState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, PagingPanel, Table, TableFilterRow, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { calculateAIRating } from '../../helpers/constant';
import { LanguageContext } from '../../provider/LanguageProvider';
import { setMessage } from '../../redux/actions/MainAction';
import FilmService from '../../services/FilmService';
import { useNavigate } from 'react-router-dom';

function FilmList(props) {
	const navigate = useNavigate();
	const classes = useStyles();
	const { translation } = useContext(LanguageContext);
	const [filmList, setFilmList] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(20);
	const [pageSizes] = useState([20, 50, 100]);
	const [totalCount, setTotalCount] = useState(0);
	const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([{ columnName: 'id', direction: 'desc' }]);
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'ratingAI', filteringEnabled: false },
    { columnName: 'id', filteringEnabled: false },
    { columnName: 'filmRateCount', filteringEnabled: false },
    { columnName: 'published', filteringEnabled: false },
    { columnName: 'action', filteringEnabled: false },
  ]);  
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'ratingAI', sortingEnabled: false },
    { columnName: 'filmRateCount', sortingEnabled: false },
    { columnName: 'published', sortingEnabled: false },
    { columnName: 'action', sortingEnabled: false },
  ]);
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}

		FilmService(true).GetFilms({ pageId: currentPage * pageSize, pageSize: pageSize, filters: filters.length> 0 ? filters[0].value : undefined , sortColumnName: sorting[0].columnName, sortDirection: sorting[0].direction })
			.then((response) => {
				prepareFilmList(response[0]);
				setTotalCount(response[1]);
			})
	}, [user, currentPage, pageSize, filters, sorting]);

	const getRowId = row => row.id;

	const publishFilm = (id, published) => {
		// Set Film publish flag and refresh film list
		FilmService().PublishFilm(id, published)
			.then(() => {
				FilmService().GetFilms({ pageId: currentPage * pageSize, pageSize: pageSize, filters: filters.length> 0 ? filters[0].value : undefined , sortColumnName: sorting[0].columnName, sortDirection: sorting[0].direction })
					.then((response) => {
						prepareFilmList(response[0]);
						setTotalCount(response[1]);
					})
			});
	}

	const prepareFilmList = (filmList) => {
		filmList.forEach(film => {
			let drug = 0, horror = 0, obscene = 0, swearing = 0, violence = 0;
			film.filmRating.forEach(rate => {
				drug = drug + parseFloat(rate.drug);
				horror = horror + parseFloat(rate.horror);
				obscene = obscene + parseFloat(rate.obscene);
				swearing = swearing + parseFloat(rate.swearing);
				violence = violence + parseFloat(rate.violence);
			});
			let calculatedFilmRating = {};
			const rateCount = film.filmRating.length;
			calculatedFilmRating.drug = (drug / rateCount).toString();
			calculatedFilmRating.horror = (horror / rateCount).toString();
			calculatedFilmRating.obscene = (obscene / rateCount).toString();
			calculatedFilmRating.swearing = (swearing / rateCount).toString();
			calculatedFilmRating.violence = (violence / rateCount).toString();
			let ratingAI = calculateAIRating(calculatedFilmRating);
			film.ratingAI = ratingAI;
		});
		setFilmList(filmList);
	}

	const onEditClicked = (row) => {
		navigate('/addFilm/' + row.imdbId);
	}

	const onDeleteClicked = (row) => {
		FilmService().DeleteFilmById({ id: row.id })
			.then((data) => {
				prepareFilmList(data);
				dispatch(setMessage({ message: 'Film silindi', severity: 'warning' }));
			})
	}

	const cellTemplatePublish = (row, columnName) => {
		let returnValue = row.published == 0
			? <Button color='primary' variant='outlined' onClick={() => { publishFilm(row.id, true) }}>Yayına Al</Button>
			: <Button color='secondary' variant='outlined' onClick={() => { publishFilm(row.id, false) }}>Yayında</Button>
		// return row.filmRating.length >= 4 ? returnValue : <div>Minimum kişi sayısına ulaşılamadı</div>;
		return returnValue;
	}

	const cellTemplateAction = (row, columnName) => {
		return (
			<div>
				<IconButton onClick={() => { onEditClicked(row) }}>
					<EditIcon />
				</IconButton>
				<IconButton onClick={() => { onDeleteClicked(row) }}>
					<DeleteIcon />
				</IconButton>
			</div>
		);
	}

	const cellTemplateFilmRateCount = (row, columnName) => {
		let limitStyle = { color: 'red', fontSize: 16 };
		let limitStyle2 = { color: 'green', fontSize: 16 };
		let style = row.filmRating.length < 4 ? limitStyle : limitStyle2;
		return (<div style={style}>{JSON.stringify(row.filmRating.length)}</div>);
	}

	const RowDetail = ({ row }) => {
		const titleStyle = { display: "flex", width: 120, marginRight: 12, fontWeight: 'bold' };
		const valueStyle = { display: "flex", width: 120, marginRight: 12 };
		const sectionStyle = { display: "flex" };

		let rates = row.filmRating.map((rate, index) => {
			var createDate = new Date(rate.createdAt).toLocaleDateString('tr');
			return (
				<div style={sectionStyle} key={index}>
					<div style={valueStyle}>{rate.userId && rate.userId.name + ' ' + rate.userId.surname}</div>
					<div style={valueStyle}>{rate.violence}</div>
					<div style={valueStyle}>{rate.obscene}</div>
					<div style={valueStyle}>{rate.swearing}</div>
					<div style={valueStyle}>{rate.horror}</div>
					<div style={valueStyle}>{rate.drug}</div>
					<div style={valueStyle}>{createDate}</div>
				</div>
			)
		})

		return (
			<div>
				<div style={sectionStyle}>
					<div style={titleStyle}>Üye Adı</div>
					<div style={titleStyle}>Şiddet</div>
					<div style={titleStyle}>Müstehcenlik</div>
					<div style={titleStyle}>Küfür</div>
					<div style={titleStyle}>Aşırı Sahneler</div>
					<div style={titleStyle}>Madde Kullanımı</div>
					<div style={titleStyle}>Tarih</div>
				</div>
				{rates}
			</div>
		)
	}

	return (
		<BasePage>
			<HeroContent>
				<Container maxWidth="lg">
					<Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>{translation.filmListFilmList}</Typography>
				</Container>
			</HeroContent>

			<Container className={classes.container} maxWidth="lg">
				<Grid item xs={12} sm={12}>
					<Paper variant="outlined">
						<DxGrid
							rows={filmList}
							columns={[
								{ name: 'id', title: translation.filmListFilmId },
								{ name: 'title', title: translation.filmListFilmName },
								{ name: 'ratingAI', title: translation.filmListRateAI },
								{ name: 'filmRateCount', title: translation.filmListFilmRateCount, getCellValue: cellTemplateFilmRateCount },
								{ name: 'published', title: translation.filmListPublished, getCellValue: cellTemplatePublish },
								{ name: 'action', title: ' ', getCellValue: cellTemplateAction },
							]}
							getRowId={getRowId}>
              <SortingState
                sorting={sorting}
                onSortingChange={setSorting}
                columnExtensions={sortingStateColumnExtensions}
              />
              <FilteringState onFiltersChange={setFilters} columnExtensions={filteringStateColumnExtensions} />
							<RowDetailState />
							<PagingState
								currentPage={currentPage}
								onCurrentPageChange={setCurrentPage}
								pageSize={pageSize}
								onPageSizeChange={(p) => {
									setCurrentPage(0);
									setPageSize(p);
								}}
							/>
							<CustomPaging totalCount={totalCount} />
							<Table columnExtensions={[
								{ columnName: 'id', width: '80' },
								{ columnName: 'title', width: 'auto', wordWrapEnabled: true },
								{ columnName: 'published', width: '150', wordWrapEnabled: true },
								{ columnName: 'action', width: '120' }
							]} />
							<TableHeaderRow showSortingControls />
              <TableFilterRow />
							<PagingPanel pageSizes={pageSizes} messages={{ rowsPerPage: 'Kayıt Sayısı:' }} />
							<TableRowDetail contentComponent={RowDetail} />
						</DxGrid>
					</Paper>
				</Grid>
			</Container>
		</BasePage>
	);
}

const useStyles = makeStyles((theme) => ({
	info: {
		'& > * + *': {
			marginLeft: theme.spacing(2),
		},
	},
	container: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	chips: {
		margin: 2
	}
}));

export default FilmList