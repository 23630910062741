import AccountCircle from '@mui/icons-material/AccountCircle';
import Check from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Home from '@mui/icons-material/Home';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import MyIcon from '../helpers/icon-helper';
import { loginChanged } from '../redux/actions/LoginAction';
import { themeList } from '../utils/enum';
import { makeStyles } from 'tss-react/mui';
import { Box, Paper } from '@mui/material';

const drawerWidth = 240;

const useStyles = makeStyles()((theme) => {
  return {
    icon: {
      marginRight: theme.spacing(2),
    },
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    cardGrid: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardMedia: {
      paddingTop: '56.25%', // 16:9
    },
    grow: {
      flexGrow: 1,
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6),
    },
    title: {
      // flexGrow: 1,
      cursor: 'pointer',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '50ch',
      },
    },
    menuButton: {
      // marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    offset: theme.mixins.toolbar
  };
});


function TopBar(props) {
  // const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loggingIn, setUserLogginIn] = React.useState(false);
  const [activeTheme, setActiveTheme] = useState('istanbul');

  const { user, menu } = useSelector(state => {
    return state;
  });
  useEffect(() => {
    if (user && user.name) {
      setUserLogginIn(true);
    }
    else {
      setUserLogginIn(false);
    }
  }, [user]);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onThemeChanged = (theme) => {
    setActiveTheme(theme);
  };

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleMenuClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleMenuItemSelected = (path) => {
    navigate(path);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem key={'profile'} onClick={() => {
          navigate('/profile');
        }}>Profil</MenuItem>
        <Divider />
        <MenuItem disabled >
          <Typography color='gray' >Tema</Typography>
        </MenuItem>
        <MenuItem key={'istanbulTheme'} onClick={() => { onThemeChanged(themeList.ISTANBUL); }}>
          <ListItemIcon>{activeTheme === themeList.ISTANBUL && <Check fontSize="small" />}</ListItemIcon>
          <ListItemText>İstanbul</ListItemText>
        </MenuItem>
        <MenuItem key={'medineTheme'} onClick={() => { onThemeChanged(themeList.MEDINE); }}>
          <ListItemIcon>{activeTheme === themeList.MEDINE && <Check fontSize="small" />}</ListItemIcon>
          <ListItemText>Medine</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem key={'exit'} onClick={() => {
          handleMenuClose();
          localStorage.removeItem('user');
          localStorage.removeItem('menu');
          localStorage.removeItem('token');
          dispatch(loginChanged({}));
          navigate('/');
        }}>Çıkış Yap</MenuItem>

      </Menu>
    )
  }




  return (
    <Box>
      <Box sx={{ flexGrow: 1, marginBottom: '24px' }}>
        <AppBar position="static"
        // className={clsx(classes.appBar, {
        //   [classes.appBarShift]: open,
        // })}
        >
          <Toolbar>
            {
              loggingIn &&
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleDrawerOpen}
              >
                <MyIcon iconName={"Menu"} />
              </IconButton>
            }
            <Typography
              // className={classes.title} 
              sx={{ flexGrow: 1 }}
              variant="h6" noWrap
              onClick={() => {
                navigate('/');
              }}>Ailecek İzliyoruz</Typography>
            <Box component="button" sx={{ display: { xs: 'block', md: 'none' } }}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => { navigate('/'); }}
                color="inherit"
              // className={clsx(classes.menuButton, open && classes.hide)}
              >
                <Home />
              </IconButton>
            </Box>
            <div
            //  className={classes.grow} 
            />

            <div
            // className={classes.grow} 
            />
            {!loggingIn && <Button color="inherit" component={Link} to="/signIn">Giriş</Button>}
            {!loggingIn && <Button color="inherit" component={Link} to="/signUp">Kaydol</Button>}
            {loggingIn && <Typography >{user.name}</Typography>}
            {loggingIn && <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>}
          </Toolbar>

        </AppBar>
      </Box>
      <Drawer
        // className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          // paper: classes.drawerPaper,
        }}
      >
        <div
        //  className={classes.drawerHeader}
        >
          <IconButton key={'Chevron'} onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {Array.isArray(menu) && menu.map((menu, index) => (
            <ListItem key={index} onClick={() => { handleMenuItemSelected(menu.path); handleDrawerClose(); }}>
              <ListItemIcon ><MyIcon iconName={menu.iconName} /></ListItemIcon>
              <ListItemText primary={menu.title} />
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>

      {renderMenu()}

    </Box>
  );
}

export default TopBar;