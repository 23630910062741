import { IntegratedPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

function Profile(props) {
	const classes = useStyles();
	const navigate = useNavigate();
	const _user = useSelector(state => state.user);
	const [userInfo, setUserInfo] = useState({});

	useEffect(() => {
		if (!_user) {
			navigate('/login');
			return;
		}

		UserService().UserProfileById({ userId: _user.id })
			.then((response) => {
				setUserInfo(response);
			})
	}, []);

	let { user, filmList } = userInfo;

	const getRowId = row => row.id;
	return (
		<BasePage maxWidth="lg">
			<HeroContent>
				<Container maxWidth="sm">
					<Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>{'Profil'}</Typography>
				</Container>
			</HeroContent>

			<Container className={classes.container} maxWidth="lg">
				<Grid item xs={12} sm={12} >
					<Paper variant="outlined" className={classes.paper}>
						<Typography component="h1" variant="h4" className={classes.headerTitle}>Bilgilerim</Typography>
						<Typography component="h1">{user && user.name}</Typography>
						<Typography component="h1">{user && user.surname}</Typography>
						<Typography component="h1">{user && user.email}</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Paper variant="outlined">
						<Typography component="h1" variant="h4" className={classes.headerTitle}>Puanladığım Filmler</Typography>
						<DxGrid
							rows={filmList || []}
							columns={[
								{ name: 'title', title: 'Film Adı', getCellValue: (row, columnName) => <div>{row.filmId.title}</div> },
								{ name: 'violence', title: 'Şiddet', },
								{ name: 'obscene', title: 'Müstehcenlik', },
								{ name: 'swearing', title: 'Küfür', },
								{ name: 'horror', title: 'Aşırı Sahneler', },
								{ name: 'drug', title: 'Madde Kullanımı', },
							]}
							getRowId={getRowId}>
							<PagingState defaultCurrentPage={0} pageSize={20} />
							<IntegratedPaging />
							<Table columnExtensions={[
								{ columnName: 'id', width: '50' },
								{ columnName: 'title', width: 'auto' },
								{ columnName: 'published', width: '150', wordWrapEnabled: true },
								{ columnName: 'action', width: '120' }
							]} />
							<TableHeaderRow />
							<PagingPanel pageSizes={[20, 50, 100]} messages={{ rowsPerPage: 'Kayıt Sayısı:' }} />
						</DxGrid>
					</Paper>
				</Grid>
			</Container>
		</BasePage>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		margin: theme.spacing(1),
		padding: theme.spacing(2)
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	heroContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
	},
	headerTitle: {
		margin: theme.spacing(1),
		padding: theme.spacing(2)
	},
	headerBox: {
		marginBottom: theme.spacing(2)
	},
}));

export default Profile;