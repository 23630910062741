import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
// import thunkMiddleware from 'redux-thunk';
import RootReducer from '../reducer/RootReducer';

const loggerMiddleware = createLogger();
const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
    menu: JSON.parse(localStorage.getItem('menu'))
};

export const store = createStore(
    RootReducer,
    initialState,
    applyMiddleware(
        // thunkMiddleware,
        // loggerMiddleware
    )
);

// export default store;