import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilmCard from '../../components/FilmCard';
import HeroContent from '../../components/HomeSections/HeroContent';
import Info from '../../components/Info';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import BasePage from '../../core/BasePage';
import { postApiConfig, url } from '../../helpers/constant';
import { useNavigate } from 'react-router-dom';

function Favorite() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [filmList, setFilmList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFilmText, setSearchFilmText] = useState('');

  const { user } = useSelector(state => { return state; });

  const getFilmList = () => {
    let apiUrl = url + '/getFavorite';
    let parameters = { userId: user.userId };
    axios.post(apiUrl, parameters, postApiConfig)
      .then(response => {
        setFilmList(response.data.filmList);
      })
      .catch((error) => {
        console.log(` ${error}`)
      });
  }

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      getFilmList();
    }
  }, [user]);

  return (
    <BasePage>
      <HeroContent>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
            Favori Listem
          </Typography>
        </Container>
      </HeroContent>
      <Container className={classes.container} maxWidth="md">
        <Grid container spacing={4}>
          {
            loading ? <LoadingSkeleton /> :
              searchFilmText && filmList.length == 0 ?
                <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', paddingTop: '24px', justifyContent: 'center' }} ><Info severity={"warning"} message={'Sonuç Bulunamadı'} ></Info></Grid>
                :
                filmList.map((film) => {
                  return (
                    <FilmCard film={film} key={film.filmId} pageUrl={'/favorite'} onLikeClicked={() => { getFilmList(); }} />
                  );
                })}
        </Grid>
      </Container>
    </BasePage>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
}));

export default Favorite
