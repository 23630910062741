import { CircularProgress, Collapse, Container, CssBaseline, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Info from '../components/Info';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setMessage } from '../redux/actions/MainAction';
import Alert from '@mui/material/Alert'
import { useLocation } from 'react-router-dom';
import TopBar from '../components/TopBar';

const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
}));

export default function BasePage(
  {
    children,
    // statusMessage = { message: '', severity: '', duration: 3000 },
    // loading,
    // message = { message: '', severity: '' },
    maxWidth = "md",
    // onCloseMessage,
    // onCloseStatusMessage
  }
) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loading, message } = useSelector(state => state.main);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  // const [infoMessage, setMessage] = useState({ message: '', severity: '' });
  // const [statusMessage, setStatusMessage] = useState({ message: '', severity: '', duration: 3000 })

  const snackBarAnchorOrigin = { vertical: 'bottom', horizontal: 'left' };

  // const handleStatusClose = () => {
  //   setStatusMessage({ message: '', severity: '', duration: 3000 })
  //   setOpen(false);
  // };
  // const handleMessageClose = () => {
  //   setMessage({ message: '', severity: '' });
  //   setMessageOpen(false);
  // };

  useEffect(() => {
    //console.log(location.pathname);
    dispatch(setMessage(null));
    // statusMessage.duration = !statusMessage.duration ? 3000 : statusMessage.duration;
    // statusMessage && setOpen(true)
    // infoMessage.message != '' && setMessageOpen(true)
    // props.message && setMessage(props.message);
    // props.message && props.message.message !== '' && setMessageOpen(true);
    // props.statusMessage && setStatusMessage(props.statusMessage)
    // props.statusMessage && props.statusMessage.message !== '' && setOpen(true);

    // return props.callback && props.callback();
  }, [location, dispatch]);

  let styleProgress = {
    position: 'fixed',
    left: '50%',
    top: '50%',
    textAlign: 'center',
    zIndex: 1600
  }

  let styleMain = {
    backgroundColor: 'rgb(0 0 0 / 51%)',
    zIndex: 1550,
    width: '100%',
    height: '100%',
    position: 'fixed'
  }

  const handleMessageClose = () => {
    dispatch(setMessage(null));
    //onCloseMessage && onCloseMessage();
  }
  const handleStatusMessageClose = () => {
    // setOpen(false)
    //onCloseStatusMessage && onCloseStatusMessage();
  }

  return (
    <React.Fragment>
      <TopBar />      
      {/* <Snackbar
        anchorOrigin={snackBarAnchorOrigin}
        open={open} autoHideDuration={statusMessage && statusMessage.duration ? statusMessage.duration : 3000}
        onClose={handleStatusMessageClose}
      >
        <Info message={statusMessage && statusMessage.message} severity={statusMessage && statusMessage.severity}
          onClose={handleStatusMessageClose} duration={statusMessage && statusMessage.duration ? statusMessage.duration : 3000}
          open={open} />
      </Snackbar> */}
      {loading && <div style={styleMain}></div>}
      {loading && <CircularProgress style={styleProgress} />}
      <main>
        {children && children.length > 0 && children[0]}
        { // message && 
          <Container className={classes.message} maxWidth={maxWidth}>
            <Collapse in={message ? true : false}>
              <div className={classes.root}>
                <Alert variant="filled" severity={message ? message.severity : 'success'} onClose={handleMessageClose}>{message ? message.message : ''}</Alert>
              </div>
            </Collapse>
            {/* <Info message={message.message} severity={message.severity}
          // onClose={handleMessageClose}
          open={message ? true : false //messageOpen
          } /> */}
          </Container>}
        {children && children.length > 0 && children[1]}
      </main>
      <Footer />
    </React.Fragment>
  )
}
