const initialState = {}

export const MenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_MENU_LIST':
            {
                let menu = action.payload;
                return menu;
            }
        default:
            return state;
    }
}