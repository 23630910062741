import ServiceCommon from "./ServiceCommon";
import { setLoading } from '../redux/actions/MainAction';
import { store } from '../redux/store/Store';

export default function AdminService() {
  store.dispatch(setLoading({ loading: true }));
	let service = new ServiceCommon();

	const RoleList = () => {		
		return service.get('/admin/roleList');
	}

	const SaveRole = (data) => {
		return service.post('/admin/saveRole', data);
	}

	const ChangeUserRole = (data) => {
		return service.post('/admin/changeUserRole', data);
	}

	const MenuList = () => {
    return service.get(`/admin/menuList`);
	}
	
	const SaveMenu = (data) => {
		return service.post('/admin/saveMenu', data);
	}

	const RoleAccessList = () => {
    return service.get(`/admin/roleAccessList`);
	}

	const SaveRoleAccess = (data) => {
		return service.post('/admin/saveRoleAccess', data);
	}
	const DeleteRoleAccess = (data) => {
		return service.post('/admin/deleteRoleAccess', data);
	}

  return {
		MenuList,
		RoleList,
		SaveRole,
		ChangeUserRole,
		SaveMenu,
		RoleAccessList,
		SaveRoleAccess,
		DeleteRoleAccess,
	}
}
