import ServiceCommon from "./ServiceCommon";
import { setLoading } from '../redux/actions/MainAction';
import { store } from '../redux/store/Store';

export default function UserService() {
  store.dispatch(setLoading({ loading: true }));
	let service = new ServiceCommon();

  const UserSignIn = (data) => {
		return service.post('/user/signin', data);
	}
	
	const UserSignUp = (data) => {
		return service.post('/user/signup', data);
	}
	
	const UserResetPassword = (data) => {
		return service.get(`/user/resetPassword/${data.token}`);
	}
	
	const UserForgotPassword = (data) => {
		return service.post('/user/forgotPassword', data);
	}
	const UserChangePassword = (data) => {
		return service.post('/user/resetPassword', data);
	}
	const GetUserList = ({ pageId, pageSize }) => {		
		return service.get('/user/userList/' + pageId + '/' + pageSize );
	}
	const UserProfileById = (data) => {
		return service.post('/user/userProfileById', data);
	}
	

  return {
		UserSignIn,
		UserSignUp,
		UserResetPassword,
		UserForgotPassword,
		UserChangePassword,
		GetUserList,
		UserProfileById
	}
}
