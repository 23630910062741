import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { asyncLocalStorage } from '../../helpers/constant';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';
import { validateEmail } from '../../helpers/utils';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { Card } from '@mui/material';
import UserService from '../../services/UserService';
import { setMessage } from '../../redux/actions/MainAction';

function ForgotPassword(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [inputs, setInputs] = useState({ email: '', password: '' });
	const [inputsError, setInputsError] = useState({ email: '' });
	const { email } = inputs;
	const { emailError } = inputsError;
	useEffect(() => {
		asyncLocalStorage.getItem('user').then((data) => {
			let userInfo = JSON.parse(data);
			if (userInfo) {
				navigate('/');
			}
		});
	}, []);

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs(inputs => ({ ...inputs, [name]: value }));
	}

	function validateSubmit() {
		let hasError = false;
		setInputsError({ emailError: '' });
		dispatch(setMessage(null));

		if (!email) {
			setError({ key: 'emailError', value: 'Lütfen e-posta adresinizi giriniz' });
			hasError = true;
		}

		if (!validateEmail(email)) {
			setError({ key: 'emailError', value: 'E-posta adresiniz hatalı' });
			hasError = true;
		}

		return hasError;
	}

	function setError({ key, value }) {
		setInputsError(inputsError => ({ ...inputsError, [key]: value }));
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (!validateSubmit()) {
			UserService().UserForgotPassword({ email })
				.then((data) => {
					dispatch(setMessage({ message: 'Şifre sıfırlama linki e-posta adresinize gönderilmiştir.', severity: 'success' }));
				});
		}
	}

	const onBlurText = (e) => {
		let { name, value } = e.target;
		name = name + 'Error';
		value = value && ''
		setInputsError(inputsError => ({ ...inputsError, [name]: value }));
	}

	return (
		<BasePage>
			<HeroContent>
				<Container maxWidth="sm">
					<Box className={classes.box}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
					</Box>
					<Typography component="h1" variant="h5" align="center" color="textPrimary" gutterBottom>Şifremi unuttum</Typography>
				</Container>
			</HeroContent>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Card className={classes.card}>
						<form className={classes.form} onSubmit={handleSubmit} noValidate>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="E-Posta Adresi"
								name="email"
								autoComplete="email"
								value={email}
								helperText={emailError}
								error={!emailError ? false : true}
								onBlur={onBlurText}
								onChange={handleChange}
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								className={classes.submit}>Tamam</Button>
							<Grid container>
								<Grid item xs><Link to="/signIn" variant="body2">{"Giriş Yap"}</Link></Grid>
								<Grid item><Link to="/signUp" variant="body2">{"Hesabın yok mu? Kaydol"}</Link></Grid>
							</Grid>
						</form>
					</Card>
				</div>
			</Container>
		</BasePage>
	);
}

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	heroContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
	},
	box: {
		display: 'flex',
		justifyContent: 'center'
	},
	card: {
		padding: theme.spacing(2)
	},
}));

export default ForgotPassword;