import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { connect } from 'react-redux';
import { FilmSection } from '../../components/HomeSections/FilmSection';
import { FilmTypeSection } from '../../components/HomeSections/FilmTypeSection';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import Logo from '../../components/Logo';
import { Helmet } from "react-helmet";
import CarouselSlider from '../../components/CarouselSlider';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  },
}));

function Home({ dispatch }) {
  const classes = useStyles();
  const [filmTypeId, setFilmTypeId] = React.useState(0);
  const onFilmTypeChanged = (filmTypeId) => {
    setFilmTypeId(filmTypeId);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ailecek İzliyoruz | çoluğumuz var çocuğumuz var!</title>
        <link rel="canonical" href="http://ailcekizliyoruz.com" />
      </Helmet>
      <BasePage>
        <HeroContent>
          <Container maxWidth="md">
            <div style={{ textAlign: "center" }}><Logo /></div>
            <Typography variant="subtitle1" align="center" color="textSecondary">{`Merhaba!`}</Typography>
            <Typography variant="subtitle2" align="center" color="textSecondary">{`Ailecek İzliyoruz ekibi sizler için filmleri kritik ediyor ve çeşitli ölçütlere göre puanlama sunuyor. Amacımız, size farklı türde filmlerle ilgili değerlendirmeler sunarak film seçiminizi kolaylaştırmak. Ne izleyeceğimi önceden bilmeliyim diyorsanız, doğru adrestesiniz.`}</Typography>
            <Typography variant="subtitle2" align="center" color="textSecondary">{`İyi seyirler!`}</Typography>
            {/* <CarouselSlider /> */}
          </Container>
        </HeroContent>
        <Container className={classes.container} maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}  >
              <FilmTypeSection onFilmTypeChanged={onFilmTypeChanged} />
            </Grid>
            <FilmSection filmTypeId={filmTypeId} xs={3} sm={3} md={3} />
          </Grid>
        </Container>
      </BasePage>

    </>
  );
}

export default Home;
