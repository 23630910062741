import { CustomPaging, PagingState, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, PagingPanel, Table, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroContent from '../../components/HomeSections/HeroContent';
import IconMenu from '../../components/IconMenu';
import BasePage from '../../core/BasePage';
import { educationList } from '../../helpers/constant';
import { setMessage } from '../../redux/actions/MainAction';
import AdminService from '../../services/AdminService';
import UserService from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

function UserList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [userList, setUserList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSizes] = useState([20, 50, 100]);
  const getRowId = row => row.id;

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    AdminService().RoleList()
      .then((data) => {
        setRoleList(data);
      });

    UserService().GetUserList({ pageId: currentPage * pageSize, pageSize: pageSize })
      .then((response) => {
        //console.log(response)
        setUserList(response[0]);
        setTotalCount(response[1])
      })
  }, [user, currentPage, pageSize]);

  const cellTemplateAction = (row, columnName) => {
    return <IconMenu optionList={roleList ? roleList.map(r => { return { id: r.id, text: r.roleName, row } }) : []}
      onSelectItem={onRoleChange} />
  }

  const onRoleChange = (roleId, userId) => {
    AdminService().ChangeUserRole({ roleId, userId })
      .then(() => {
        dispatch(setMessage({ message: 'Kullanıcı yetkisi güncellendi', severity: 'success' }));
        UserService().GetUserList({ pageId: currentPage * pageSize, pageSize: pageSize })
          .then((response) => {
            setUserList(response[0]);
          })
      })
  }

  const RowDetail = ({ row }) => {
    const titleStyle = { display: "flex", width: 120, marginRight: 12, fontWeight: 'bold' };
    const valueStyle = { display: "flex", width: 120, marginRight: 12 };
    const sectionStyle = { display: "flex" };

    let rates = row.filmRating.map((rate) => {
      return (
        <div style={sectionStyle}>
          <div style={valueStyle}>{rate.filmId && rate.filmId.title}</div>
          <div style={valueStyle}>{rate.violence}</div>
          <div style={valueStyle}>{rate.obscene}</div>
          <div style={valueStyle}>{rate.swearing}</div>
          <div style={valueStyle}>{rate.horror}</div>
          <div style={valueStyle}>{rate.drug}</div>
        </div>
      )
    })

    return (
      <div>
        <div style={sectionStyle}>
          <div style={titleStyle}>Film Adı</div>
          <div style={titleStyle}>Şiddet</div>
          <div style={titleStyle}>Müstehcenlik</div>
          <div style={titleStyle}>Küfür</div>
          <div style={titleStyle}>Aşırı Sahneler</div>
          <div style={titleStyle}>Madde Kullanımı</div>
        </div>
        {rates}
      </div>
    )
  }

  return (
    <BasePage maxWidth="lg">
      <HeroContent>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>{'Kullanıcı Listesi'}</Typography>
        </Container>
      </HeroContent>

      <Container className={classes.container} maxWidth="lg">
        <Grid item xs={12} sm={12}>
          <Paper variant="outlined">
            <DxGrid
              rows={userList}
              columns={[
                { name: 'id', title: 'Id' },
                { name: 'name', title: 'Adı' },
                { name: 'surname', title: 'Soyadı' },
                { name: 'email', title: 'E-posta' },
                { name: 'filmRating', title: 'Film Sayısı', getCellValue: (row, columnName) => { return row[columnName].length; } },
                { name: 'gender', title: 'Cinsiyet', getCellValue: (row, columnName) => { return row[columnName] == 1 ? 'Erkek' : "Kadın" } },
                { name: 'roleId', title: 'Yetki Grubu', getCellValue: (row, columnName) => { return roleList.find(r => r.id == row[columnName]).roleName } },
                { name: 'birthDate', title: 'Doğum Tarihi', getCellValue: (row, columnName) => { return new Date(row[columnName]).getFullYear('tr'); } },
                { name: 'education', title: 'Eğitim', getCellValue: (row, columnName) => { return educationList.find(e => e.id == row[columnName]).text }  },
                { name: 'action', title: ' ', getCellValue: cellTemplateAction },
              ]}
              getRowId={getRowId}>
              <RowDetailState />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={(p) => {
                  setCurrentPage(0);
                  setPageSize(p);
                }}
              />
              <CustomPaging totalCount={totalCount} />
              <Table columnExtensions={[
                { columnName: 'id', width: '50' },
                { columnName: 'action', width: '64' }
              ]} />
              <TableHeaderRow />
              <PagingPanel pageSizes={pageSizes} messages={{ rowsPerPage: 'Kayıt Sayısı:' }} />
              <TableRowDetail contentComponent={RowDetail} />
            </DxGrid>
          </Paper>
        </Grid>
      </Container>

    </BasePage>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  box: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    padding: theme.spacing(2)
  },
}));

export default UserList;