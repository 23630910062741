import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import Copyright from './Copyright';
import { makeStyles } from '@mui/styles';
import Collapse from '@mui/material/Collapse';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        // padding: theme.spacing(2),
    },
}));

export default function Info({ message, severity, onClose, open }) {
    const classes = useStyles();
    const [infoMessage, setMessage] = useState('');
    const [infoOpen, setOpen] = useState(false);

    useEffect(()=> {
        message && setMessage(message);
        message && setOpen(true);
    }, [message, open]);

    const handleClose = () => {
        setMessage('');
        setOpen(false);
        onClose && onClose();
    }

    return (

        <Collapse in={infoOpen}>
            <div className={classes.root}>
                <Alert variant="filled" onClose={handleClose} severity={severity}>{infoMessage}</Alert>
            </div>
        </Collapse>
    );
}