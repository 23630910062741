// export const url = 'https://www.ailecekizliyoruz.com/api';
// export const url = 'https://api.ailecekizliyoruz.com';
// export const url = 'http://192.168.1.37:3030';
// export const url = 'http://localhost:3000';
export const url = getURL();

function getURL() {
	console.log("process.env.NODE_ENV", process.env.NODE_ENV);
	if (process.env.NODE_ENV === "development") {
		return 'http://localhost:3000/api';
	}
	else if (process.env.NODE_ENV === "test") {
		return 'https://test.ailecekizliyoruz.com/api';
	}
	else if (process.env.NODE_ENV === "production") {
		return 'https://www.ailecekizliyoruz.com/api';
	}
}

export function getRateColor(value) {
	if (value >= 9 && value <= 10) {
		return '#ff0000';
	}
	else if (value >= 5 && value < 9) {
		return '#ffa500';
	}
	else {
		return '#6fc200'
	}

	// TODO: 5 emoji dönecek şekilde düzenlenmesi
}

export function getRateColorReverse(value, { high, medium, low }) {
	if (value >= medium && value <= high) {
		return '#6fc200';
	}
	else if (value >= low && value < medium) {
		return '#ffa500';
	}
	else {
		return '#ff0000';
	}
}

export function calculateAIRating(filmRating) {
	let result = (
		(10 - filmRating.violence) +
		(10 - filmRating.obscene) +
		(10 - filmRating.swearing) +
		(10 - filmRating.horror) +
		(10 - filmRating.drug)
	) / 5;

	return result.toFixed(1);
}

export const rateType = {
	VIOLENCE: 1,
	OBSCENE: 2,
	SWEARING: 3,
	HORROR: 4,
	DRUG: 5
}

export const filmStatus = {
	NOT_APPROVED: 0,
	APPROVED: 1,
}

export const asyncLocalStorage = {
	setItem: function (key, value) {
		return Promise.resolve().then(function () {
			localStorage.setItem(key, value);
		});
	},
	getItem: function (key) {
		return Promise.resolve().then(function () {
			return localStorage.getItem(key);
		});
	}
};

export const postApiConfig = {
	headers: {
		"content-type": "application/json",
		"Authorization": `Bearer ${localStorage.getItem('token')}`
	}
};

export const getApiConfig = {
	headers: {
		"Content-type": "application/json"
	}
};

export const genreList = {
	"Action": { filmTypeId: 1, name: "Aksiyon" },
	"Adult": { filmTypeId: 2, name: "Yetişkin" },
	"Adventure": { filmTypeId: 3, name: "Macera" },
	"Animation": { filmTypeId: 4, name: "Animasyon" },
	"Biography": { filmTypeId: 5, name: "Biyografi" },
	"Comedy": { filmTypeId: 6, name: "Komedi" },
	"Crime": { filmTypeId: 7, name: "Suç" },
	"Documentary": { filmTypeId: 8, name: "Belgesel" },
	"Drama": { filmTypeId: 9, name: "Dram" },
	"Family": { filmTypeId: 10, name: "Aile" },
	"Fantasy": { filmTypeId: 11, name: "Fantezi" },
	"Film Noir": { filmTypeId: 12, name: "Kara film" },
	"Game Show": { filmTypeId: 13, name: "Oyun şovu" },
	"History": { filmTypeId: 14, name: "Tarih" },
	"Horror": { filmTypeId: 15, name: "Korku" },
	"Musical": { filmTypeId: 16, name: "Müzikal" },
	"Music": { filmTypeId: 17, name: "Müzik" },
	"Mystery": { filmTypeId: 18, name: "Gizem" },
	"News": { filmTypeId: 19, name: "Haberler" },
	"Reality-TV": { filmTypeId: 20, name: "Gerçeklik TV" },
	"Romance": { filmTypeId: 21, name: "Romantik" },
	"Sci-Fi": { filmTypeId: 22, name: "Bilim kurgu" },
	"Short": { filmTypeId: 23, name: "Kısa" },
	"Sport": { filmTypeId: 24, name: "Spor" },
	"Talk-Show": { filmTypeId: 25, name: "Sohbet programı" },
	"Thriller": { filmTypeId: 26, name: "Gerilim" },
	"War": { filmTypeId: 27, name: "Savaş" },
	"Western ": { filmTypeId: 28, name: "Batı" },
}

export const imdbTypeList = {
	"movie": "film",
	"series": "dizi",
	"episode": "bölüm",
	"TVSeries": "dizi"
}

export const contractText = () => {

	return `“Ailecek İzliyoruz”
	Kişisel Verilerin Korunması Kanunu Kapsamında
	İnternet Sitesi Üye Aydınlatma Metni
	Biz, Ailecek İzliyoruz Ekibi (www.ailecekizliyoruz.com) olarak, kişisel verilerinizin sizler için ne kadar önemli olduğunun farkındayız. Bu nedenle sizleri kişisel verilerinizin işlenmesine ilişkin olarak bilgilendirmek isteriz.
	İşbu Aydınlatma Metni, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 10. Maddesi kapsamında, www.ailecekizliyoruz.com internet sitesinin Kaydol/Üye ol bölümüne istinaden bizimle paylaşabileceğiniz kişisel verileriniz ile ilgili olarak, veri sorumlusu sıfatıyla Ailecek İzliyoruz Ekibi tarafından, sizi aydınlatmak ve bilgilendirmek amacıyla hazırlanmıştır. 
	Veri Sorumlusunun Kimliği 
	İşbu İnternet Sitesi, Ailecek İzliyoruz ekibine ait olup, tarafımızla paylaşabileceğiniz kişisel verileriniz, veri sorumlusu sıfatı ile www.ailecekizliyoruz.com nezdinde muhafaza edilecek ve korunacaktır. 
	Kişisel Verilerinizin İşlenme Amacı 
	Kişisel Verileriniz, www.ailecekizliyoruz.com internet sitesi üyelik başvurusu internet sitesi üzerinden doldurduğunuz başvuru formu kapsamında elektronik ortamda aktardığınız belgeler vasıtasıyla toplanmakta ve işlenmektedir. İşbu Kaydol Formu üzerinden bizimle paylaşacağınız ad-soyad, e-posta, telefon numarası kişisel verilerinizi, internet sitesi üyelik kaydının oluşturulabilmesi, üyelik oluşturma esnasında kimlik doğrulabilmesi yapılabilmesi ve kullanım sözleşmesinde yer alan kullanım şartlarının uygunluğunun sağlanması amacıyla işlemekteyiz.
				 İlgili Kişilerin Hakları
	KVK Kanunu’nun 11 inci maddesinde yer alan haklarınızı, KVK Kanunu’nun              13 üncü maddesi ile Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ‘in (“Veri Sorumlusuna Başvuru Tebliği”) ilgili hükümleri uyarınca Bakanlığımıza başvuru yapmak suretiyle kullanabilirsiniz.
	
	Burada yer alan haklarınız konusunda taleplerinizi Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e göre bize Küçük Çamlıca Mah. İkizler Çıkmazı No:1/1 Üsküdar, İSTANBUL adresine ve bilgi@ailecekizliyoruz.com mail adresine  iletebilirsiniz. 
	`

}

export const educationList = [
	{ id: 1, text: "Lise" },
	{ id: 2, text: "Önlisans" },
	{ id: 3, text: "Lisans" },
	{ id: 4, text: "Yüksek Lisans" },
	{ id: 5, text: "Doktora" }
];
