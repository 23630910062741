import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { InputLabel, MenuItem, Select } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
// import 'date-fns';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { asyncLocalStorage, contractText, educationList } from '../../helpers/constant';
import { validateEmail } from '../../helpers/utils';
import { loginChanged } from '../../redux/actions/LoginAction';
import { setMessage } from '../../redux/actions/MainAction';
import { changeMenuList } from '../../redux/actions/MenuAction';
import UserService from '../../services/UserService';


function SignUp() {
	const classes = useStyles();
	// const [inputs, setInputs] = useState({ name: 'a', surname: 'd', email: 'mustafayonca@gmail.com', password: '123' });
	const [inputs, setInputs] = useState({ name: '', surname: '', email: '', password: '', birthDate: '1' });
	const [inputsError, setInputsError] = useState({ nameError: '', surnameError: '', emailError: '', passwordError: '' });
	const [contractApprove, setContractApprove] = useState(false);
	const [education, setEducation] = useState();
	const [genderValue, setGenderValue] = useState(1);
	const [open, setOpen] = useState(false);
	const { name, surname, email, password, birthDate } = inputs;
	const { nameError, surnameError, emailError, passwordError } = inputsError;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	function handleSubmit(e) {
		e.preventDefault();
		if (!validateFormHasError()) {
			UserService().UserSignUp({ name, surname, email, password, education, contractApprove, gender: genderValue, birthDate })
				.then((data) => {
					if (!data.user) {
						dispatch(setMessage({ message: data, severity: 'error', duration: 5000 }));
					}
					dispatch(loginChanged(data.user));
					dispatch(changeMenuList(data.menu));
					asyncLocalStorage.setItem('user', JSON.stringify(data.user));
					asyncLocalStorage.setItem('menu', JSON.stringify(data.menu));
					navigate('/');
				});
		}
	}

	function handleChange(e) {
		const { name, value } = e.target;
		setInputs(inputs => ({ ...inputs, [name]: value }));
	}

	function setError({ key, value }) {
		setInputsError(inputsError => ({ ...inputsError, [key]: value }));
	}

	function validateFormHasError() {
		let hasError = false;
		setInputsError({ nameError: '', surnameError: '', emailError: '', passwordError: '', password2Error: '' })
		dispatch(setMessage(null));
		// setStatusMessage({message: '', severity: ''});
		// setStatusMessage({ message: 'Hata Oluştu', severity: 'error', duration: 30000 })
		if (!name) {
			setError({ key: 'nameError', value: 'Lütfen adınızı giriniz' });
			hasError = true;
		}
		if (!surname) {
			setError({ key: 'surnameError', value: 'Lütfen soyadınızı giriniz' });
			hasError = true;
		}
		if (!email) {
			setError({ key: 'emailError', value: 'Lütfen e-posta adresinizi giriniz' });
			hasError = true;
		}
		else {
			if (!validateEmail(email)) {
				setError({ key: 'emailError', value: 'E-posta adresiniz hatalı' });
				hasError = true;
			}
		}
		if (!password) {
			setError({ key: 'passwordError', value: 'Lütfen şifrenizi giriniz' });
			hasError = true;
		}
		if (!hasError) {
			if (!contractApprove) {
				dispatch(setMessage({ message: 'Üyeklik sözleşmesini onaylayınız', severity: 'error' }));
				hasError = true;
			}
		}

		return hasError;
	}

	const handleGenderChange = (event) => {
		setGenderValue(parseInt(event.target.value));
	};

	const onBlurText = (e) => {
		let { name, value } = e.target;
		name = name + 'Error';
		value = value && ''
		setInputsError(inputsError => ({ ...inputsError, [name]: value }));
	}

	return (
		<BasePage maxWidth={'sm'} >
			<HeroContent>
				<Container maxWidth="sm">
					<Box className={classes.box}>
						<Avatar className={classes.avatar}>
							<LockOutlinedIcon />
						</Avatar>
					</Box>
					<Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>Üye Ol</Typography>
				</Container>
			</HeroContent>
			<Container component="main" maxWidth="sm">
				<div className={classes.paper}>
					<Card className={classes.card}>
						<form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete='off'>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12}>
									<TextField
										name="name"
										variant="outlined"
										required
										fullWidth
										id="name"
										label="Adınız"
										value={name}
										helperText={nameError}
										error={!nameError ? false : true}
										onBlur={onBlurText}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={12}>
									<TextField
										name="surname"
										variant="outlined"
										required
										fullWidth
										id="surname"
										label="Soyadınız"
										value={surname}
										helperText={surnameError}
										error={!surnameError ? false : true}
										onBlur={onBlurText}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										id="email"
										label="E-posta Adresi"
										name="email"
										autoComplete="off"
										value={email}
										helperText={emailError}
										error={!emailError ? false : true}
										onBlur={onBlurText}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextField
										variant="outlined"
										required
										fullWidth
										name="password"
										label="Şifre"
										type="password"
										id="password"
										autoComplete="new-password"
										helperText={passwordError}
										error={!passwordError ? false : true}
										onBlur={onBlurText}
										value={password}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormControl variant="outlined" style={{ display: 'flex' }}>
										<InputLabel id="demo-simple-select-outlined-label">Eğitim Durumu</InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											value={education}
											onChange={(event) => { setEducation(event.target.value) }}
											label="Eğitim Durumu">
											{
												educationList.map((edu, index) => <MenuItem key={index} value={edu.id}>{edu.text}</MenuItem>)
											}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<TextField
										variant="outlined"
										required
										fullWidth
										name="birthDate"
										label="Doğum Yılı"
										type="number"
										id="birthDate"
										value={birthDate}
										onChange={handleChange}
									/>
									{/* <LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker 
										disableFuture
										inputVariant="outlined"
										openTo="year"
										format="yyyy"
										label="Doğum Yılınız"
										views={["year"]}
										value={birthDate}
										onChange={handleDateChange}
										/>
									</LocalizationProvider> */}
								</Grid>
								<Grid item xs={6}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Cinsiyet</FormLabel>
										<RadioGroup aria-label="gender" name="gender1" value={genderValue} onChange={handleGenderChange}>
											<FormControlLabel value={1} control={<Radio />} label="Erkek" />
											<FormControlLabel value={2} control={<Radio />} label="Kadın" />
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<FormControlLabel
										control={<Checkbox value="contractApprove" color="primary" checked={contractApprove} onChange={
											(event) => {
												setContractApprove(event.target.checked);
											}} />}
										label="Üyelik sözleşmesini onaylıyorum."
									/>
									<Link color="inherit" to={"/"} href="/" onClick={() => { setOpen(true); }}>Sözleşme Metni</Link>
									<Dialog
										open={open}
										// onClose={handleClose}
										scroll={'paper'}
										aria-labelledby="scroll-dialog-title"
										aria-describedby="scroll-dialog-description"
									>
										<DialogTitle id="scroll-dialog-title">Sözleşme Metni</DialogTitle>
										<DialogContent dividers>
											<DialogContentText
												id="scroll-dialog-description"
												// ref={descriptionElementRef}
												tabIndex={-1}
											>
												{contractText()}
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={() => { setOpen(false); setContractApprove(true) }} color="primary">Onayla</Button>
											<Button onClick={() => { setOpen(false); }} color="primary">İptal</Button>
										</DialogActions>
									</Dialog>
								</Grid>
								<Grid item xs={12}>
									{/* {submitError && <Info message={submitError} severity={'error'} />}
								{submitSuccess && <Info message={submitSuccess} severity={'success'} />} */}
									{/* {inProgress && <LinearProgress />} */}
								</Grid>
							</Grid>
							<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Kaydet</Button>
							<Grid container justify="flex-end">
								<Grid item>
									<Link to="/signIn" variant="body2">Üyeliğin var mı? Giriş Yap</Link>
								</Grid>
							</Grid>
						</form>
					</Card>
				</div>
			</Container>
		</BasePage>
	);
}


const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	heroContent: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(8, 0, 6),
	},
	box: {
		display: 'flex',
		justifyContent: 'center'
	},
	card: {
		padding: theme.spacing(2)
	},
	formControl: {
		// display: 'flex'
	}
}));

export default SignUp