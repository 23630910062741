import { createTheme } from "@mui/material";


const istanbulTheme = createTheme({
	palette: {
		primary: {
			main: '#664e9a',
		},
		secondary: {
			main: '#ea5656'
		},
		error: {
			main: '#f44336'
		},
		warning: {
			main: '#ff9800'
		},
		info: {
			main: '#2196f3'
		},
		success: {
			main: '#4caf50'
		}
	}
});

export default istanbulTheme