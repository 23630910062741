import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { getRateColor } from '../helpers/constant';
import ProgressBar from './ProgressBar';

const useStyles = makeStyles((theme) => ({
  rating: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
	},
	title: {
		fontSize: 10
	},
	box: {
		width: '100%'
	}
}));

function FilmRating(props) {
	const classes = useStyles();

	let { rateValue, title, variant } = props;
	let style = { width: '42px', backgroundColor: getRateColor(rateValue) };
	return (
		<Grid item style={{ //paddingTop: '5px' 
		}}>
			
			<div className={classes.rating}>
				<Typography variant={variant} display="block" className={classes.title} style={{ minWidth: '80px'}} gutterBottom>{title}</Typography>				
				<Box ml="2" className={classes.box}><ProgressBar indicatorValue={rateValue} /></Box>
				{/* <Box ml="2"><Chip color="primary" size="small" label={rateValue} style={style} /></Box> */}
			</div>
		</Grid>
	);
}

export default FilmRating;