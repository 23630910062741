import { createTheme } from '@mui/material/styles';
import CeraPro from '../fonts/cerapro.woff2';

const cerapro = {
	fontFamily: 'CeraProBlack',
	// fontStyle: 'semi-bold',
	// fontDisplay: 'swap',
	// fontWeight: '600',
	src: `
		local('CeraProBlack'),
		local('Cera-Pro-Black'),
		url(${CeraPro}) format('woff2')
	`,
	unicodeRange:
		"U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF UTF-8",
};

const medineTheme = createTheme({
	typography: {
		fontFamily: ['CeraProBlack'].join(','),
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [cerapro],
			},
		}
	},
	palette: {
		primary: {
			main: '#ea5656'
		},
		secondary: {
			main: '#664e9a'
		},
		error: {
			main: '#f44336'
		},
		warning: {
			main: '#ff9800'
		},
		info: {
			main: '#2196f3'
		},
		success: {
			main: '#4caf50'
		}
	}
});

export default medineTheme