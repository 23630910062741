import { EditingState, IntegratedPaging, PagingState, RowDetailState } from '@devexpress/dx-react-grid';
import { Grid as DxGrid, PagingPanel, Table, TableEditColumn, TableEditRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@mui/material';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { setMessage } from '../../redux/actions/MainAction';
import AdminService from '../../services/AdminService';
import { useNavigate } from 'react-router-dom';


function RoleAccess(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const [roleList, setRoleList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [roleAccessList, setRoleAccessList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageSizes] = useState([20, 50, 100]);
  const getRowId = row => row.id;

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    AdminService().RoleAccessList()
      .then((response) => {
        setRoleAccessList(response);
      });
    AdminService().MenuList()
      .then((response) => {
        setMenuList(response);
      });
    AdminService().RoleList()
      .then((response) => {
        setRoleList(response);
      });
  }, [user, currentPage, pageSize]);

  const cellTemplate = (row, columnName) => {
    if (row && row.menuId && row.roleId) {
      if (columnName === "menuId") {
        return menuList.length > 0 ? menuList.find(m => m.id === row.menuId).title : '';
      }
      else if (columnName === "roleId") {
        return roleList.length > 0 ? roleList.find(r => r.id === row.roleId).roleName : '';
      }
    }
  }

  const cellEditTemplate = ({ column, row }) => {
    return (<TableEditRow.Cell>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={row[column.name]}
          onChange={(event) => {
            console.log('sadASd');
            row[column.name] = event.target.value
          }}>
          {
            column.name === 'menuId' ?
              menuList.map(p => <MenuItem value={p.id}>{p.title}</MenuItem>) :
              roleList.map(p => <MenuItem value={p.id}>{p.roleName}</MenuItem>)
          }
        </Select>
      </FormControl></TableEditRow.Cell>
    )
  }

  const commitChanges = ({ added, changed, deleted }) => {
    let changedRows;
    if (added) {
      const startingAddedId = roleAccessList.length > 0 ? roleAccessList[roleAccessList.length - 1].id + 1 : 0;
      changedRows = [
        ...roleAccessList,
        ...added.map((row, index) => ({
          id: startingAddedId + index,
          ...row,
        })),
      ];
      AdminService().SaveRoleAccess(added[0])
        .then((response) => {
          dispatch(setMessage({ message: 'Yeni yetki tanımı kaydedildi', severity: 'success' }));
          setRoleAccessList(changedRows);
        })
    }

    if (deleted) {
      const deletedSet = new Set(deleted);
      changedRows = roleAccessList.filter(row => !deletedSet.has(row.id))

      AdminService().DeleteRoleAccess(deleted[0])
      .then(() => {
        dispatch(setMessage({ message: 'Yetki tanımı silindi', severity: 'success' }));
        setRoleAccessList(changedRows);
      })
    }
  };

  return (
    <BasePage maxWidth="sm">
      <HeroContent>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>{'Yetkilendirme'}</Typography>
        </Container>
      </HeroContent>

      <Container className={classes.container} maxWidth="sm">
        <Paper variant="outlined" className={classes.paper}>
          <DxGrid
            rows={roleAccessList}
            columns={[
              { name: 'menuId', title: 'Menü', getCellValue: cellTemplate },
              { name: 'roleId', title: 'Rol', getCellValue: cellTemplate },
            ]}
            getRowId={getRowId}>
            <EditingState onCommitChanges={commitChanges} />
            <RowDetailState />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={(p) => {
                setCurrentPage(0);
                setPageSize(p);
              }}
            />
            <IntegratedPaging />
            <Table columnExtensions={[
              { columnName: 'menuId', width: 'auto' },
              { columnName: 'roleId', width: '150' },
            ]} />
            <TableHeaderRow />
            <TableEditRow cellComponent={cellEditTemplate} />
            <TableEditColumn showAddCommand showDeleteCommand messages={{ addCommand: 'Yeni Ekle', deleteCommand: 'Sil', commitCommand: 'Kaydet', cancelCommand: 'İptal' }} />
            <PagingPanel pageSizes={pageSizes} messages={{ rowsPerPage: 'Kayıt Sayısı:' }} />
          </DxGrid>
        </Paper>
      </Container>

    </BasePage>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  box: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    padding: theme.spacing(2)
  },
}));

export default RoleAccess;