import { useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import TopBar from './components/TopBar';
import AddFilm from './pages/film/AddFilm';
import FilmDetail from './pages/film/FilmDetail';
import FilmList from './pages/film/FilmList';
import UserFilmList from './pages/film/UserFilmList';
import Home from './pages/main/Home';
import UserList from './pages/user/UserList';
import SignIn from './pages/user/SignIn';
import SignUp from "./pages/user/SignUp";
import Profile from "./pages/user/Profile";
import ForgotPassword from "./pages/user/ForgotPassword";
import ResetPassword from "./pages/user/ResetPassword";
import Role from "./pages/admin/Role";
import RoleAccess from "./pages/admin/RoleAccess";
import Menu from "./pages/admin/Menu";
import Favorite from "./pages/main/Favorite";
// import ScrollToTop from './ScrollToTop'
import ErrorHandler from "./pages/error/ErrorHandler";
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import themeList from './theme/theme';
import { Provider } from "react-redux";
import { store } from "./redux/store/Store";
import { LanguageProvider } from "./provider/LanguageProvider";
import { fetchTranslations } from "./helpers/utils";

function App() {
	const theme = createTheme(themeList.istanbul);
	  

	return (
		<>
			<Provider store={store}>
				<LanguageProvider fetchTranslations={fetchTranslations}>
					<ThemeProvider theme={theme}>
						<CssBaseline />
						{/* <ScrollToTop /> */}
						{/* <TopBar /> */}
						{/* <ErrorHandler> */}
						<Routes>
							<Route key={"home"} path='/' element={<Home />} />
							<Route path="/addFilm/:imdbCode" Component={AddFilm} />
							<Route path="/addFilm" Component={AddFilm} />
							<Route path="/filmList" Component={FilmList} />
							<Route path="/userfilmList" Component={UserFilmList} />
							<Route path="/films/:id/:title" Component={FilmDetail} />
							<Route path="/signUp" Component={SignUp} />
							<Route path="/signIn" Component={SignIn} />
							<Route path="/profile" Component={Profile} />
							<Route path="/forgotPassword" Component={ForgotPassword} />
							<Route path="/resetPassword/:token" Component={ResetPassword} />
							<Route path="/userList" Component={UserList} />
							<Route path="/role" Component={Role} />
							<Route path="/roleAccess" Component={RoleAccess} />
							<Route path="/menu" Component={Menu} />
							<Route path="/favorite" Component={Favorite} />
							{/* <Route path="/:id" children={<Child />} /> */}
						</Routes>
						{/* </ErrorHandler> */}
					</ThemeProvider>
				</LanguageProvider>
			</Provider>
		</>
	);
}

export default App
