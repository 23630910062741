import LeftArrowIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import FilmCard from '../../components/FilmCard';
import HeroContent from '../../components/HomeSections/HeroContent';
import BasePage from '../../core/BasePage';
import { convertGenre2 } from '../../helpers/utils';
import FilmService from '../../services/FilmService';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	heroButtons: {
		marginTop: theme.spacing(4),
	},
	container: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	cardMedia: {
		paddingTop: '145%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
	},
	posterContent: {
		paddingBottom: 0,
		paddingTop: 0
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));

function FilmDetail() {
	const location = useLocation();
	const navigate = useNavigate();
	const classes = useStyles();
	const [film, setFilm] = useState({});

	let backUrl = location.state && location.state.backUrl;

	let { id, title } = useParams();

	let infoStyle = { paddingLeft: '24px', textAlign: 'right' };
	let infoTitleStyle = { fontWeight: 'bold' };
	let cardContentStyle = { display: 'flex', justifyContent: 'space-between' };
	let gridContentStyle = { paddingLeft: 0, paddingRight: 0 };

	useEffect(() => {
		FilmService().GetFilmById(id)
			.then((data) => {
				setFilm(data);
			});
	}, [id]);

	film.filmGenre && convertGenre2(film.filmGenre);
	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Ailecek İzliyoruz | çoluğumuz var çocuğumuz var! | {location.state ? location.state.filmTitle : ""}</title>
				<meta name='description' content={film.plot} />
				<link rel="canonical" href={"http://ailcekizliyoruz.com/film" + id + "/" + title.toLowerCase().replace(/ /g, '-')} />
			</Helmet>
			<BasePage>
				<HeroContent>
					<Container maxWidth="sm">
						<Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
							{film.title}
						</Typography>
						<Typography component="h6" variant="h6" align="center" color="textPrimary" gutterBottom>
							{film.titleTR}
						</Typography>
					</Container>
				</HeroContent>
				<Container className={classes.container} maxWidth="md">
					<Grid container spacing={2}>
						<Grid item key={film.name} xs={12} sm={12} md={12}>
							<Button
								variant={'outlined'}
								size="medium"
								color="secondary"
								startIcon={<LeftArrowIcon />}
								onClick={() => {
									navigate(backUrl ? backUrl : '/');
								}}
							>Geri Dön</Button>
						</Grid>

						<Grid item key={'deneme'} xs={12} sm={3} md={3}>
							{film && film.id && <FilmCard key={film.id} film={film} pageUrl={'filmdetail/' + film.id} onLikeClicked={() => {
								// getFilmList({ pageId: activePageId, filmType: activeFilmType }); 
							}} />}

						</Grid>
						<Grid item key={film} xs={12} sm={9} md={9}>
							<Card className={classes.card}>
								<CardContent className={classes.cardContent}>
									<Typography gutterBottom variant="h5" component="h2">Film Hakkında</Typography>
									<Typography style={{ paddingBottom: '20px' }}>{film.plot}</Typography>

									<Grid container spacing={4}>
										<Grid item xs={12} sm={6} md={6} style={cardContentStyle}>
											<Typography variant="body1" style={infoTitleStyle}>Yapım Yılı</Typography>
											<Typography variant="body1" style={infoStyle}>{film.year}</Typography>
										</Grid>
										<Grid item xs={12} sm={6} md={6} style={cardContentStyle}>
											<Typography variant="body1" style={infoTitleStyle}>Süre</Typography>
											<Typography variant="body1" style={infoStyle}>{film.runtime}</Typography>
										</Grid>
										<Grid item xs={12} sm={6} md={6} style={cardContentStyle}>
											<Typography variant="body1" style={infoTitleStyle}>Tür</Typography>
											<Typography variant="body1" style={infoStyle}>{film.genre}</Typography>
										</Grid>
										<Grid item xs={12} sm={6} md={6} style={cardContentStyle}>
											<Typography variant="body1" style={infoTitleStyle}>Orijinal Dil</Typography>
											<Typography variant="body1" style={infoStyle}>{film.language}</Typography>
										</Grid>
										<Grid item xs={12} sm={6} md={6} style={cardContentStyle}>
											<Typography variant="body1" style={infoTitleStyle}>Direktör</Typography>
											<Typography variant="body1" style={infoStyle}>{film.director}</Typography>
										</Grid>
										<Grid item xs={12} sm={6} md={6} style={cardContentStyle}>
											<Typography variant="body1" style={infoTitleStyle}>Aktör(ler)</Typography>
											<Typography variant="body1" style={infoStyle}>{film.actor}</Typography>
										</Grid>
										{film && film.filmWarning && film.filmWarning.length > 0 &&
											<Grid item xs={12} sm={12} md={12} style={gridContentStyle}>
												<Table size="small" >
													<TableHead>
														<TableRow>
															<TableCell style={{ fontWeight: 'bold' }}>Film Uyarısı</TableCell>
															<TableCell style={{ fontWeight: 'bold' }}>Başlangıç Zamanı</TableCell>
															<TableCell style={{ fontWeight: 'bold' }}>Bitiş Zamanı</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{
															film.filmWarning.map(data => {
																return (
																	<TableRow key={data.id}>
																		<TableCell>{data.label}</TableCell>
																		<TableCell>{data.startTime}</TableCell>
																		<TableCell>{data.endTime}</TableCell>
																	</TableRow>
																)
															})
														}
													</TableBody>
												</Table>
											</Grid>
										}
										<Grid item xs={12} sm={12} md={12}>
											<Typography variant="body1" style={infoTitleStyle}>Film Türleri</Typography>
											{
												film.filmGenre && 
												film.filmGenre.map((data, index) =>
													<Chip key={index} variant="outlined" color="secondary" size="small" label={data.name} style={{ margin: 2 }} />
												)
											}
										</Grid>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</BasePage>
		</>
	);
}
export default FilmDetail;