import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import noimage from '../image/noimage.png';

const useStyles = makeStyles((theme) => ({
	card: {
		height: '100%',
		boxShadow: "none",
		// border: '1px solid #e1e1e1'
		// display: 'flex',
		// flexDirection: 'column',
		// marginLeft: 8,
		// marginRight: 8
	},
	cardMedia: {
		paddingTop: '145%', // 16:9
	},
	cardContent: {
		flexGrow: 1,
		paddingBottom: 0,
		padding: 8
	},
	paper: {
		padding: 2,
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
}));


function SearchFilmCard({ film, handleOnClick }) {
	const classes = useStyles();
	return (
		<Grid item key={film.imdbID} xs={1} sm={1} md={1} style={{ minWidth: 100, margin: 4}}  >
			<Card className={classes.card}>
				<CardActionArea onClick={() => { handleOnClick({ film }) }}>
					{
						<CardMedia
							className={classes.cardMedia}
							image={film.Poster ? film.Poster : noimage}
							title={film.Title}
						/>
					}

				{/* <CardContent className={classes.cardContent} style={{ paddingBottom: '0px' }}>
					<Tooltip title={film.title} placement="top" arrow>
						<Typography gutterBottom variant="caption">
							{film.title}
						</Typography>
					</Tooltip>
				</CardContent> */}
				</CardActionArea>
				<Tooltip title={film.Title} placement="top" arrow>
						<Typography gutterBottom variant="caption">
							{film.Title}
						</Typography>
					</Tooltip>
			</Card>
		</Grid>
	)
}

export default SearchFilmCard;