import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import uniqueId from 'lodash.uniqueid';

const ITEM_HEIGHT = 48;

export default function IconMenu({ optionList, onSelectItem }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (roleId, userId) => {
    if (roleId > 0 && userId > 0) {
      onSelectItem(roleId, userId);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        style={{ padding: 0 }}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {optionList.map((option) => (
          <MenuItem key={option.id + uniqueId()} onClick={() => { handleClose(option.id, option.row.id) }}>
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
