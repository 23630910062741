import ServiceCommon from "./ServiceCommon";
import { setLoading } from '../redux/actions/MainAction';
import { store } from '../redux/store/Store';

export default function FilmService(hideLoading = false) {
	store.dispatch(setLoading({ loading: !hideLoading }));
	var service = new ServiceCommon();

	const GetFilmsBySearchText = ({ searchText, page }) => {
		return service.get('/film/getFromIMDBByName/' + searchText + '/' + page);
	}

	const GetIMDBfilm = ({ imdbId }) => {
		return service.get('/film/getFromIMDBById/' + imdbId);
	}

	const GetFilmTypesNew = () => {
		return service.get('/film/filmType');
	}

	const GetFilmList = ({ pageId, pageSize, filmTypeId }) => {
		return service.get('/film/allFilm/' + pageId + '/' + pageSize + '/' + filmTypeId);
	}

	// const GetFilms = ({ pageId, pageSize }) => {		
	// 	return service.get('/film/films/' + pageId + '/' + pageSize);
	// }
	const GetFilms = ({ pageId, pageSize, filters, sortColumnName, sortDirection }) => {
		return service.get('/film/films/' + pageId + '/' + pageSize + '/' + filters + '/' + sortColumnName + '/' + sortDirection);
	}

	const GetFilmsByFilter = ({ pageId, pageSize, filters }) => {
		return service.get('/film/films/' + pageId + '/' + pageSize + '/' + filters);
	}

	const IsFilmExist = ({ imdbId, userId }) => {
		return service.get('/film/imdbFilms/' + imdbId + '/' + userId);
	}

	const AddFilmService = (data) => {
		return service.post('/film/addFilm', data);
	}

	const UpdateFilmService = (data) => {
		return service.post('/film/updateFilm', data);
	}

	const GetFilmById = (id) => {
		return service.get('/film/films/' + id);
	}

	const PublishFilm = (id, published) => {
		return service.post('/film/publishFilm', { id, published });
	}

	const DeleteFilmById = (data) => {
		return service.post('/film/deleteFilmById', data);
	}

	const FilmForSearch = (text) => {
		return service.get('/film/filmForSearch?search=' + text);
	}


	const GetLast10Film = () => {
		return service.get('/film/getLast10Film');
	}

	return {
		GetFilmTypesNew,
		GetFilmList,
		GetIMDBfilm,
		AddFilmService,
		IsFilmExist,
		GetFilms,
		// GetFilms2,
		GetFilmById,
		PublishFilm,
		UpdateFilmService,
		DeleteFilmById,
		FilmForSearch,
		GetFilmsByFilter,
		GetFilmsBySearchText,
		GetLast10Film
	};
}

