import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react'

export const Paging = ({ filmTypeId, handlePageChange, filmCount }) => {
	const [pageCount, setPageCount] = useState(filmCount);

	useEffect(() => {
		setPageCount(Math.ceil(filmCount / 12))
	}, [filmCount]);

	const onPageChange = (event, value) => {
		handlePageChange(value);
	};
	let pageComponent = [];
	pageComponent = pageCount > 1 && <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', paddingTop: '24px', justifyContent: 'center' }} ><Pagination count={pageCount} color="primary" onChange={onPageChange} /></Grid>
	return (pageComponent)
}
