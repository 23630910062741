import { genreList, imdbTypeList } from "./constant";
import tr from '../translation/tr';
import en from '../translation/en';


function  validateEmail(email) {
  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  return expression.test(String(email).toLowerCase())
}

function prepareIMDBData(data) {  
  let { title, plot, year, releaseDate, directors, starList, runtimeMins, genres, countries, languages, imDbRating, id, posters, type } = data;
  let poster = posters && posters.posters && posters.posters[0].link;
  // Runtime = Runtime === 'N/A' ? '0' : Runtime.replace('min', '');
  let genre = convertGenre(genres);
  imDbRating = imDbRating === 'N/A' ? '0' : imDbRating;
  type = convertMovieType(type);
  const actors = convertActorList(starList);
  return { title, plot, year: parseInt(year), releaseDate, directors, actors, runtime: parseInt(runtimeMins), genre, countries, languages, imDbRating, imdbId: id, poster, type } ;
}

function convertActorList(starList) {
  const starNameList = starList.map(star => star.name);
  return starNameList.join();
}

function convertGenre(genres) {
  genres = genres.replace(/\s/g, '');
  const genreArray = genres.split(',');
  let convertedGenreList = genreArray.map(item => {
    return genreList[item];
  });

  return convertedGenreList;
}

function convertGenre2(genres) {
  genres.forEach(genre => {
    let genreArray = Object.values(genreList);
    let name = genreArray.find(p => p.filmTypeId == genre.filmTypeId) ? genreArray.find(p => p.filmTypeId == genre.filmTypeId).name : '';
    genre.name = name;
  });
}

function convertMovieType(type) {
  let convertedType = imdbTypeList[type];
  return convertedType;
}

function fetchTranslations({ language = "tr" }) {
	// import(`../translation/${language}`).then((module) => {
	// 	return module.default
  // })
  let strings = {};

  switch (language) {
    case "en":
      strings = en;
      break;
    case "tr":
      strings = tr;
      break;
    default:
      break;
  }
  return strings;
}
  
export {
  validateEmail,
  prepareIMDBData,
  fetchTranslations,
  convertGenre2
}