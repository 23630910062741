import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import FilmService from '../../services/FilmService';
import FilmCard from '../FilmCard';
import LoadingSkeleton from '../LoadingSkeleton';
import { Paging } from '../Paging';

export const FilmSection = ({ filmTypeId, xs, sm, md }) => {

	const [filmList, setFilmList] = useState();
	const [filmCount, setFilmCount] = useState();

	useEffect(() => {
		FilmService().GetFilmList({ pageId: 0, pageSize: 12, filmTypeId })
			.then((response) => {
				let filmList = response[0];
				let count = response[1];
				setFilmList(filmList);
				setFilmCount(count);
			})
	}, [filmTypeId]);

	const { user } = useSelector(state => { return state; });

	const handlePageChange = (value) => {
		FilmService().GetFilmList({ pageId: (value - 1) * 12, pageSize: 12, filmTypeId })
			.then((response) => {
				let filmList = response[0];
				let count = response[1];
				setFilmList(filmList);
				setFilmCount(count);
			});
	};

	let films = [];
	let paging = <Paging key={1} filmCount={filmCount} filmTypeId={filmTypeId} handlePageChange={handlePageChange} />;

	

	if (filmList) {
		films.push(filmList.map((film, index) => {
			return (
					<FilmCard key={film.id} xs={xs} sm={sm} md={md} film={film} pageUrl={'/'} onLikeClicked={() => {
						// getFilmList({ pageId: activePageId, filmType: activeFilmType }); 
					}} />
			);
		}));
	} else {
		// films.push(<LoadingSkeleton key={1} />);
	}

	return ([films, paging])
}
