import React from 'react';
import Typography from '@mui/material/Typography';
import Copyright from './Copyright';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));
export default function Footer() {
  const classes = useStyles();
	return (
		<footer className={classes.footer}>
			<Typography variant="h6" align="center" gutterBottom>Ailecek İzliyoruz</Typography>
			<Copyright />
		</footer>
	);
}